<script setup lang="ts">
import { watch } from 'vue';

import { TButton } from '@/components/ui/button';
import { TDialog, TDialogClose, TDialogContent, TDialogTrigger } from '@/components/ui/dialog';
import { useDepositDialog } from '@/hooks/invest/use-deposit-dialog';
import { useUserMeQuery } from '@/queries/users/use-users-me-query';
import { DepositDialogState } from '@/types/invest/deposit-dialog-state-enum';

import DepositDialogContentInformations from './DepositDialogContentInformations.vue';
import DepositDialogContentIntro from './DepositDialogContentIntro.vue';

const emits = defineEmits<{
  (e: 'click'): void;
}>();

const stateComponents: Record<DepositDialogState, Component> = {
  [DepositDialogState.Intro]: DepositDialogContentIntro,
  [DepositDialogState.Informations]: DepositDialogContentInformations,
};

const { isOpen, currentState, closeDialog, changeModalState } = useDepositDialog();

const { data: user, isLoading: isUserLoading } = useUserMeQuery();

const setDefaultState = (isDialogOpen: boolean): void => {
  if (isDialogOpen) {
    changeModalState(DepositDialogState.Intro);
  }
};

watch(isOpen, (newValue) => {
  setDefaultState(newValue);
});
</script>

<template>
  <TDialog v-model:open="isOpen">
    <TDialogTrigger as-child>
      <TButton variant="secondary" size="fluid-default" @click="emits('click')">
        {{ $t('layout.navBar.userDropdown.deposit') }}
      </TButton>
    </TDialogTrigger>
    <TDialogContent
      class="h-full max-h-[700px] max-w-[1200px] grid-rows-[auto_minmax(0,1fr)_auto] gap-0 bg-beige-100 p-0"
      @interact-outside="closeDialog"
    >
      <template #modal-close>
        <TDialogClose />
      </template>
      <div v-if="isUserLoading" class="flex h-full w-full items-center justify-center">
        <TWaveLoader />
      </div>
      <component :is="stateComponents[currentState]" v-else-if="isOpen" :user="user" />
    </TDialogContent>
  </TDialog>
</template>
