<script setup lang="ts">
import { ref, watch } from 'vue';

import {
  TCommand,
  TCommandEmpty,
  TCommandGroup,
  TCommandItem,
  TCommandList,
} from '@/components/ui/command';
import TInput from '@/components/ui/input/TInput.vue';
import { TPopover, TPopoverTrigger, TPopoverContent } from '@/components/ui/popover';
import { TWaveLoader } from '@/components/ui/wave-loader';

interface Props<T extends string> {
  modelValue: T;
  items: { value: number; label: string }[];
  isLoading?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props<string>>(), {
  isLoading: false,
  emptyMessage: 'No items.',
  placeholder: 'Address',
  disabled: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'update:autoCompleteValue', value: number): void;
}>();

const open = ref(false);
const searchValue = ref('');

const onSelectItem = (event: CustomEvent<{ value: number }>) => {
  emit('update:autoCompleteValue', event.detail.value);

  searchValue.value = props.items[event.detail.value].label;
  addressInput.value?.blur();
  open.value = false;
};

const handleKeyDown = (e: KeyboardEvent) => {
  open.value = e.key !== 'Escape';
};

const handleMouseDown = () => {
  open.value = true;
};

const handleInteractOutside = (e: Event) => {
  if (e.target instanceof Element && e.target.hasAttribute('cmdk-input')) {
    e.preventDefault();
  }
};

const addressInput = ref<HTMLInputElement | null>(null);

watch(
  () => props.modelValue,
  (newValue) => {
    searchValue.value = newValue;
  },
);
</script>

<template>
  <div class="flex items-center">
    <TPopover v-model="open">
      <TCommand :should-filter="false" class="shadow-none">
        <TPopoverTrigger @keyup.stop.prevent @keydown.stop.prevent>
          <TInput
            ref="addressInput"
            v-model="searchValue"
            :placeholder="placeholder"
            hide-icon
            :auto-focus="false"
            :disabled="disabled"
            @keydown.stop="handleKeyDown"
            @mousedown="handleMouseDown"
            @focus="open = true"
          />
        </TPopoverTrigger>
        <TCommandList v-if="!open" aria-hidden="true" class="hidden" />
        <TPopoverContent
          class="w-[--radix-popover-trigger-width] p-0"
          @open-auto-focus="(e) => e.preventDefault()"
          @interact-outside="handleInteractOutside"
        >
          <TCommandList>
            <TCommandGroup v-if="items.length > 0 && !isLoading">
              <TCommandItem
                v-for="option in items"
                :key="option.value"
                :value="option.value"
                @mousedown.prevent
                @select="onSelectItem"
              >
                {{ option.label }}
              </TCommandItem>
            </TCommandGroup>
            <TCommandEmpty v-if="!isLoading">
              {{ emptyMessage }}
            </TCommandEmpty>
            <TCommandEmpty v-else>
              <TWaveLoader size="sm" />
            </TCommandEmpty>
          </TCommandList>
        </TPopoverContent>
      </TCommand>
    </TPopover>
  </div>
</template>
