<script setup lang="ts">
import { ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    fileAccept?: string;
    multiple?: boolean;
    inputClasses?: string;
    disablePreview?: boolean;
    disableClick?: boolean;
  }>(),
  {
    fileAccept: 'image/png, image/jpeg, image/jpg',
    multiple: false,
    inputClasses: 'w-full',
    disablePreview: false,
    disableClick: false,
  },
);

const model = defineModel<File | FileList | null>({ default: null });

const isMobile = ref(false);
const dragging = ref(false);
const fileInput = ref<HTMLInputElement | null>(null);

const showPreview = computed(() => model.value !== null && !props.disablePreview);

const onDragEnter = () => {
  dragging.value = true;
};

const onDragOver = () => {
  dragging.value = true;
};

const onDragLeave = () => {
  dragging.value = false;
};

const onFileDrop = (event: DragEvent) => {
  const files = event.dataTransfer!.files;
  if (files.length > 0) {
    addFiles(files);
  }
  dragging.value = false;
};

const handleFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files!.length > 0) {
    addFiles(files!);
  }
};

const openFileDialog = (force = false) => {
  if (!props.disableClick || force) {
    fileInput.value?.click();
  }
};

const addFiles = (files: FileList) => {
  model.value = props.multiple ? files : files[0];
};
</script>

<template>
  <div
    class="drag-and-drop-uploader boxed"
    :class="{ 'is-dragging': dragging, 'border-solid': showPreview || isMobile }"
    @dragover.prevent="onDragOver"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onFileDrop"
  >
    <template v-if="showPreview">
      <slot name="preview" :current-file="model">
        <p>preview</p>
      </slot>
    </template>
    <template v-else>
      <div :class="inputClasses" @click="openFileDialog">
        <input
          ref="fileInput"
          class="hidden"
          type="file"
          :accept="fileAccept"
          :multiple="multiple"
          @change="handleFileChange"
        />
        <slot name="message">
          <p class="text-center">message</p>
        </slot>
      </div>
    </template>
  </div>
</template>
