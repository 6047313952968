<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import ResetPasswordDialogNewPasswordContent from '@/components/auth/ResetPasswordDialogNewPasswordContent.vue';
import ResetPasswordDialogSendEmailContent from '@/components/auth/ResetPasswordDialogSendEmailContent.vue';
import ResetPasswordDialogWaitEmailContent from '@/components/auth/ResetPasswordDialogWaitEmailContent.vue';
import { TDialog, TDialogClose, TDialogContent } from '@/components/ui/dialog';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

const stateComponents: Record<ResetPasswordDialogState, Component> = {
  [ResetPasswordDialogState.sendEmail]: ResetPasswordDialogSendEmailContent,
  [ResetPasswordDialogState.waitForEmail]: ResetPasswordDialogWaitEmailContent,
  [ResetPasswordDialogState.NewPassword]: ResetPasswordDialogNewPasswordContent,
};

const route = useRoute();

const currentEmail = ref('');

onMounted(() => {
  setTimeout(() => {
    if (route.query['reset-password-token']) {
      openDialog(ResetPasswordDialogState.NewPassword);
    }
  }, 700);
});

const { isOpen, currentState, openDialog } = useResetPasswordDialog();
</script>

<template>
  <TDialog :open="isOpen">
    <TDialogContent
      class="h-full max-h-[700px] max-w-[1200px] grid-rows-[auto_minmax(0,1fr)_auto] bg-beige-100 p-0"
    >
      <template #modal-close>
        <TDialogClose />
      </template>
      <component
        :is="stateComponents[currentState]"
        :current-email="currentEmail"
        @update:email="currentEmail = $event"
      />
    </TDialogContent>
  </TDialog>
</template>
