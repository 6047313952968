<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{ prefix: string; canEdit?: boolean }>(), {
  canEdit: false,
});

const emits = defineEmits<{
  (e: 'edit'): void;
}>();

import { useUserMeQuery } from '@/queries/users/use-users-me-query';

const { data: user } = useUserMeQuery();

const bankAccountInformation = computed(() => {
  if (!user.value?.bankAccounts?.length) return;

  const bankAccount = user.value.bankAccounts[0];

  const owner =
    user.value.kyc?.legalForm === 'INDIVIDUAL'
      ? `${user.value.kyc?.firstName} ${user.value.kyc?.lastName}`
      : user.value.kyc?.companyName;

  return {
    owner,
    bankName: bankAccount.bankName,
    accountNumber: bankAccount.accountNumber,
  };
});
</script>

<template>
  <div
    class="flex flex-col gap-6 rounded-xl border border-primary-300 bg-white p-4 text-primary-500"
  >
    <div class="flex justify-between gap-2">
      <span class="text-primary-600">{{ props.prefix }}</span>
      <RouterLink
        v-if="props.canEdit"
        to="/settings"
        class="cursor-pointer text-primary-700 underline underline-offset-2"
        @click="emits('edit')"
      >
        {{ $t('common.modify') }}
      </RouterLink>
    </div>
    <div>
      <div>{{ bankAccountInformation?.owner }}</div>
      <div>{{ bankAccountInformation?.bankName }}</div>
      <div>{{ bankAccountInformation?.accountNumber }}</div>
    </div>
  </div>
</template>
