import { computed, ref, Ref } from 'vue';

import { KycProcessData } from '@/models/kyc/kyc-process-data.ts';
import { UserKyc } from '@/schemas/kyc/user-kyc-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { UserStatus } from '@/types/users/user-status-enum.ts';

const kycData: Ref<KycProcessData | null> = ref(null);

export function useKycProcess(user?: User, kyc?: UserKyc) {
  if (kycData.value === null) {
    if (kyc && user) {
      kycData.value = KycProcessData.fromUserAndKyc(user, kyc);
    } else if (user) {
      kycData.value = KycProcessData.fromUser(user);
    } else {
      kycData.value = KycProcessData.fromDefault();
    }
  }

  const internalKycData: Ref<KycProcessData> = kycData as Ref<KycProcessData>;

  const resetKycData = () => {
    kycData.value = null;
  };

  const updateLastEmailCodeGeneratedAt = (date: Date) => {
    internalKycData.value.data.lastEmailCodeGeneratedAt = date;
  };

  const isCodeExpired = () => {
    const fifteenMinutesInMs = 15 * 60 * 1000;
    if (internalKycData.value.data.lastEmailCodeGeneratedAt === null) return true;

    return (
      Date.now() - internalKycData.value.data.lastEmailCodeGeneratedAt.getTime() >
      fifteenMinutesInMs
    );
  };

  const canSendCode = () => {
    const fiveMinutesInMs = 5 * 60 * 1000;
    if (internalKycData.value.data.isEmailVerified) return false;
    if (isCodeExpired() || internalKycData.value.data.lastEmailCodeGeneratedAt === null)
      return true;

    return (
      Date.now() - internalKycData.value.data.lastEmailCodeGeneratedAt.getTime() > fiveMinutesInMs
    );
  };

  const mustSendCode = computed(() => {
    return !user?.emailVerified;
  });

  return {
    kycData: internalKycData,
    updateLastEmailCodeGeneratedAt,
    canSendCode,
    mustSendCode,
    isCodeExpired,
    resetKycData,
  };
}
