/* eslint-disable max-len  */
/* eslint-disable sonarjs/no-duplicate-string */

import { COMMON_FORM_ERRORS } from '@/constants/form-error-constants.ts';
import { CorrelationAsset } from '@/types/charts/correlation-asset-enum.ts';

export default {
  message: {
    hello: 'hello world',
  },
  layout: {
    navBar: {
      asset: 'Asset',
      portfolio: 'Portfolio',
      resources: 'Resources',
      createAccount: 'Create Account',
      login: 'Login',
      logout: 'Logout',
      userDropdown: {
        withdraw: 'Withdraw',
        withdrawNoFundsTooltip: 'You need to have funds to make a withdrawal.',
        deposit: 'Deposit',
        balance: 'Balance',
        available: 'available',
        items: {
          settings: 'Settings',
          documents: 'Documents',
          logout: 'Logout',
        },
      },
    },
  },
  component: {
    bankAccountEditCard: {
      holder: 'Holder',
      bankName: 'Bank name',
      accountNumber: 'IBAN',
      tooltipNoDeleteOnlyOne: 'You need to add a new bank account to delete this one.',
      tooltipNoDeleteDefault: "You can't delete your selected bank account.",
    },
    asset: {
      headerTooltip:
        'Homaio allows you to invest in the EUA Spot market. In the spot market, EUAs are traded for immediate delivery, as opposed to futures contracts which are agreements to buy or sell at a specified price at a later date.',
      marketIndicator: {
        open: 'Market open',
        close: 'Market close',
      },
      simulator: {
        lockTitle: 'This simulator is locked for our users',
        lockButton: 'Create account to unlock simulator',
      },
      srri: {
        scale: {
          safe: 'Safe',
          careful: 'Careful',
          balanced: 'Balanced',
          dynamic: 'Dynamic',
        },
        risk: {
          low: 'Low risk',
          high: 'High risk',
        },
      },
      footer: {
        title: 'Regulatory Notices',
        company: {
          name: 'HOMAIO CARBON HOLDINGS',
          form: 'Société anonyme au capital social de 37.000 €',
          address: '35 rue de Courcelles, 75008 Paris, France',
          rcs: '948 095 591 R.C.S. Paris',
          description1:
            'The past financial performance of EU Emission Allowances (EUAs) does not guarantee future returns. Potential investors are advised to carefully assess the associated risks and uncertainties before committing to any investment. The documents made available by Homaio Carbon Holdings SA through a separate and dedicated environment detail the potential risks, fees, charges, and uncertainties.',
          description2:
            'Investing in carbon allowances exposes investors to partial or total loss of capital. The value of an investment may fluctuate upwards or downwards. Investors who are not prepared for the total loss of their investment should not invest.',
          description3:
            'The information on this site does not constitute an offer to buy or sell financial securities, nor is it a prospectus for the acquisition or sale of financial securities. It may be subject to legal, regulatory, or other restrictions in certain jurisdictions. Homaio Carbon Holdings SA asks visitors to be aware of and comply with the rules applicable to them. Homaio Carbon Holdings SA accepts no responsibility for any violation of these rules by visitors to this site.',
          description4:
            'The offer or offers of financial securities issued by Homaio Carbon Holdings SA will be available to potential investors in a separate and dedicated environment, clearly indicated as such. Any investor wishing to subscribe to the securities issued by Homaio Carbon Holdings is invited to thoroughly review the terms and conditions of the subscription agreement for said securities. This agreement is clearly displayed to the concerned investors.',
          description5:
            'No information on this site or elsewhere should be considered as an investment recommendation in any security. Homaio Carbon Holdings SA does not provide any investment or financial advice.',
        },
      },
    },
    passwordInput: {
      placeholder: 'Password',
    },
    pageLockedWrapper: {
      title: 'This page is locked for our users',
      button: 'Create account to unlock the portfolio',
    },
    deposit: {
      from: 'From',
      bankInformation: {
        bankName: 'Bank name',
        accountName: 'Account name',
        bic: 'BIC/SWIFT',
        iban: 'IBAN',
        internalReference: 'Internal reference',
      },
    },
    buy: {
      entryFeesAmount: 'including {value} of fees',
      payWith: 'Pay with',
      eurWallet: 'EUR Wallet',
      noPayment: 'No payment',
      available: 'available',
      remaining: 'remaining',
      notEmitted: 'Hold {value} tCO2 that will not be emitted by industries',
      whyAmountChanged: 'Why was the amount I entered changed?',
      whyAmountChangedDescription:
        'Your amount was changed because you cannot purchase fractions of EUAs. Therefore, we rounded down to the nearest whole EUA, which also affected the originally entered amount.',
      transactionDetails: 'Transactions details',
      processDetails: 'Buy process details',
      confirm: 'Confirm',
      continue: 'Continue',
      acceptTerms: 'I have read and accept the terms and conditions of the following',
      contract: 'contract',
      acceptPrivacy: 'I consent that any communication may be communicated to me electronically',
      signContract: 'Sign contract',
      buyEUAs: 'Buy EUAs',
      investment: 'Your investment:',
      assets: 'Your assets:',
      paidWith: 'Paid with',
      transactionFeesIncluded: 'Transaction fees included',
      seePortfolio: 'See portfolio',
      congratulations: 'Congratulations 🎉',
      equivalentTo: 'equivalent to {value} HCH Bonds',
      purchasingPrice: 'Purchasing price',
      steps: {
        contractSignature: 'Contract signature',
        confirmation: 'Order confirmed by Homaio',
        orderProcessing: 'Order processing on the market',
        assetReception: 'Reception of EUAs',
      },
    },
    withdraw: {
      processDetails: 'Withdraw process details',
      confirm: 'Confirm',
      continue: 'Continue',
      receive: 'Receive',
      available: 'available',
      max: 'Max',
      from: 'From',
      to: 'To',
      steps: {
        requestReceived: 'Request received',
        confirmation: 'Withdrawal executed',
      },
    },
  },
  page: {
    resources: {
      title: 'The resources page is coming soon!',
      description:
        'We are currently developing the new resources page and we would like to hear your thoughts to ensure it meets your needs and expectations. Please take a moment to share your feedback by completing our brief questionnaire through the link below. Your input is highly valued.',
      button: 'Design this page with us',
    },
    impact: {
      title: 'Impact',
      overview: {
        title: 'Overview',
        tooltipInstallations: 'Stationary industrial facility covered by EU ETS',
        tooltipCountries: 'number of countries involved in EU ETS',
        tooltipCoveredEmissions: "Share of EU's CO2 emissions covered by the EU ETS",
        tooltipTarget2030: 'Emissions reduction target compared to 2005 emissions',
        tooltipCompliance:
          'The compliance rate measures the percentage of emission allowances held by companies that matches their actual emissions for a given period. It reflects how well companies are adhering to their regulatory requirements under the EU ETS. A high compliance rate indicates that companies are effectively managing their emissions and meeting their obligations, while a low rate may suggest issues with adherence or reporting.',
        tooltipYearlyReductionRate:
          "The yearly reduction rate represents the percentage by which the total number of emission allowances is expected to decrease each year. This rate is part of the EU's strategy to progressively lower carbon emissions.",
        installations: { label: 'Installations', tooltip: '' },
        countries: { label: 'Countries', tooltip: '' },
        coveredEmissions: { label: 'Covered emissions', tooltip: '' },
        target2030: { label: 'Target 2030', tooltip: '' },
        compliance: { label: 'Compliance', tooltip: '' },
        yearlyReductionRate: { label: 'Yearly reduction rate', tooltip: '' },
      },
      CO2Emissions: {
        title: 'CO2 emissions',
        tooltip:
          'This graph represents the EU emissions of CO2. It shows how emissions have decreased over time thanks to the EU ETS, which sets limits on the total amount of CO2 that can be emitted and encourages reductions through the trading of emission allowances.',
        measureUnit: 'Gt CO2',
        change: '@:page.impact.CO2Emissions.measureUnit this year',
      },
      emissionsVariations: {
        title: 'Emissions variations',
        tooltip:
          'Emissions variations show the reduction of CO2 emissions across the European Union over time.',
      },
      emissionsReductionTarget: {
        title: 'Emissions reduction target',
        tooltip: '',
        description:
          'The emissions reduction target is a specific goal set by the European Commission to lower CO2 emissions by -63% within 2030.',
        link: 'Want to know more? Visit our website',
        measureUnit: 'Gt CO2',
      },
      fundsAllocated: {
        title: 'Funds allocated',
        tooltip:
          'Revenues from EUAs are used to finance climate adaptation and mitigation projects across Europe. These funds are redistributed to Member States for national climate policies and support social, modernization, and innovation initiatives. As EUA prices rise, these revenues also increase, reinforcing essential public climate policies.',
        measureUnit: 'B€',
        change: '@:page.impact.fundsAllocated.measureUnit this year',
      },
      simulator: {
        title: 'Your potential returns',
        investmentAmount: 'Investment amount',
        CO2Equivalent: {
          label: 'Not emitted by industries',
          measureUnit: 'tCO2',
        },
        citizensEquivalent: {
          label: 'Equivalent to',
          measureUnit: 'years',
          result: 'of average emissions per EU citizen 👤',
        },
      },
    },
    performance: {
      title: 'Performance',
      separatorTitle: 'Your potential returns',
      volatilityBlock: {
        title: 'Synthetic risk and reward indicator (SRRI)',
        tooltip:
          'Volatility measures how much the price of an asset fluctuates over time. Higher volatility indicates larger price swings, while lower volatility suggests more stable price movements.',
        volatility: 'Volatility',
        description:
          'The SRRI is used to indicate the level of risk of a financial asset (usually a financial fund) by providing a number from 1 to 7, with 1 representing low risk and 7 representing high risk. EUAs have an SRRI of 6/7 and are in the “high risk, high reward” category.',
      },
      priceTargetBlock: {
        title: 'Price target',
        link: 'Want to know more? Visit our website',
        description:
          "The price target is an estimate of an asset's future value, based on expert reports and analysis. It reflects the expected price range that experts anticipate the asset will reach over a specific period. For this asset, experts widely agree on a price appreciation, projecting a consensus estimate of €120 to €160 by 2030.",
        research: 'Research',
        published: 'published on',
        expectedPrice: 'Expected price',
        predictions: {
          sebGroup: {
            description: 'Banking company',
            date: 'Apr, 2023',
          },
          bloomberg: {
            description: 'Financial and market data research',
            date: 'May, 2024',
          },
          cake: {
            description: 'Scientific climate science research',
            date: 'Nov, 2022',
          },
          pik: {
            description: 'Scientific climate science research',
            date: 'Nov, 2022',
          },
          morganStanley: {
            description: 'Banking company',
            date: 'Feb, 2024',
          },
          refinitiv: {
            description: 'Financial and market data research',
            date: 'Nov, 2022',
          },
          icis: {
            description: 'Energy research data center',
            date: 'Nov, 2022',
          },
          enerdata: {
            description: 'Energy research data center',
            date: 'Nov, 2023',
          },
        },
      },
      annualReturnBlock: {
        title: 'Annualized return on investment',
        tooltip:
          'This metric shows the average yearly return of an investment, expressed as a percentage.,',
      },
      correlationBlock: {
        title: 'Comparison to other assets',
        pastPerformances: 'Past performances',
        priceCorrelation: 'Price correlation',
        clickOnAsset: 'Click on one or multiple assets above to compare it',
        tooltipCorrelation:
          'Price correlation measures how the price movements of two assets are related. A positive correlation means that as one asset’s price rises or falls, the other asset’s price tends to move in the same direction. A negative correlation means they move in opposite directions.',
        tooltipPastPerformances:
          'Past performance refers to the historical returns of an asset over a specific period. In our analysis, we use a base amount of €100 to compare and calculate how the value of each asset has changed over time.',
        assets: {
          [CorrelationAsset.EUA]: 'EUA',
          [CorrelationAsset.BITCOIN]: 'Bitcoin',
          [CorrelationAsset.SP500]: 'S&P500',
          [CorrelationAsset.OIL]: 'Oil',
          [CorrelationAsset.GAS]: 'Gas',
          [CorrelationAsset.COAL]: 'Coal',
          [CorrelationAsset.GOLD]: 'Gold',
          [CorrelationAsset.T_BILL]: 'T-Bill',
        },
      },
      euaPerformanceBlock: {
        price: 'Price',
        tooltip:
          'This value measures the volatility of the European Union Allowance (EUA) in relation to the S&P 500. A beta of 1 means the asset moves in line with the S&P 500. A beta greater than 1 indicates higher volatility than the market, while a beta less than 1 suggests lower volatility.',
        stats: {
          high: 'Highest',
          low: 'Lowest',
          beta: 'Beta',
          volatility: 'Volatility',
          volume: 'Volume',
        },
      },
      simulationBlock: {
        investmentAmount: 'Investment amount',
        investmentDuration: 'Investment duration',
        forecastPortfolioValue: 'Forecast portfolio value',
        assumptionsAnnualReturn: 'Assumptions annual return',
        subtitle: '*Investment at risk. Past performance is not indicative of future returns.',
        notice: {
          title: 'How the simulation works',
          description:
            'The simulation of your portfolio presented above does not guarantee any returns. Past\n' +
            'performance is not indicative of future performance. Your investment may deviate from the\n' +
            'scenario, and you could lose all or part of your investment in extreme cases.',
          darkBlueArea:
            'The dark blue curve represents the estimated value of your portfolio. It is calculated based on studies from external experts, which you can view in the section: "price target".',
          blueArea: 'The blue area indicates the most likely evolution of your investment.',
          lightBlueArea: 'The light blue area indicates the possible evolution of your investment.',
        },
      },
      investmentSidebar: {
        invest: 'Invest',
        buy: 'Equivalent',
        bookACall: 'Book a call',
        partnerCode: 'Partner code',
        apply: 'Apply',
        minimumInvestment: 'Minimum investment amount is 1000 €',
        maximumInvestment: 'To invest 100K+,',
        enterAmount: 'Enter amount',
        contactUs: 'contact us',
        equivalentTooltip:
          'The quantity of Homaio Carbon Holdings bonds you subscribe to corresponds to the equivalent number of underlying assets: EUAs.',
        tooltips: {
          currentPrice:
            'This is the actual EUA market price. This is an estimated price of purchase as it can fluctuate until your purchase.',
          entryFees:
            'Transaction fees are fees of first purchase. This is a percentage of your investment amount.',
          custodyFees:
            'Custody fees are a percentage of the average value of your portfolio throughout the year. We average the daily values based on the closing price of EUAs. These fees are deducted on each anniversary date of your subscription. The percentage amounts depend on the size of your investment.',
          lockupPeriod:
            'The lockup period is a set time frame during which investors are prohibited from selling their EUAs. This period ensures stability by preventing immediate sales and helps to avoid market volatility.',
        },
        informations: {
          currentPrice: 'Market price',
          entryFees: 'Transaction fees',
          custodyFees: 'Custody fees',
          lockupPeriod: 'Lockup period',
          partnerCode: 'Partner code',
        },
      },
    },
    productInformation: {
      title: 'Product information',
      investmentTermsBlock: {
        title: 'Investment terms',
        subtitle: 'Our fees are simple and transparent. There is no hidden fees, no exit fees',
        bookACall: 'Book a call',
        lockupDuration: '3 months',
        footerTitle: 'Are you a partner or would you like to invest more than €100,000?',
        footerSubtitle: 'Book a call with us to draw up your personalised offer.',
        tables: {
          tooltips: {
            investmentFees: 'The cost charged when you make an initial investment.',
            custodyFees: 'Charges for safely storing your investment assets.',
            exitFees: 'The cost applied when you withdraw or sell your investment.',
            lockupPeriod:
              'Lockup period: "The time during which you cannot sell and withdraw your investment.',
            keyInformation:
              'A summary of important details about the investment, including risks and costs.',
          },
          investmentFees: 'Transaction fees',
          custodyFees: 'Custody fees',
          exitFees: 'Exit fees',
          lockupPeriod: 'Lockup period',
          keyInformation: 'Key information document',
          reduced: 'reduced',
          sixMonths: '6 months',
        },
      },
      investmentBlock: {
        title: 'Investment with Homaio',
        factsheet: 'Factsheet',
        information: [
          {
            left: 'Product name',
            right: 'Homaio Carbon Holdings Bond',
          },
          {
            left: 'Asset type',
            right: 'Corporate bonds',
          },
          {
            left: 'Holding type',
            right: 'Direct',
          },
          {
            left: 'Issuer',
            right: 'Homaio Carbon Holdings (subsidiary of Homaio SAS)',
          },
          {
            left: 'Custody account',
            right: 'Caisse des dépôts',
          },
          {
            left: 'Security agent',
            right: 'AFS',
          },
          {
            left: 'Liquidity',
            right: 'Market price',
          },
          {
            left: 'Minimum investment',
            right: '1000 €',
          },
        ],
      },
      underlyingAssetBlock: {
        title: 'The underlying asset',
        information: [
          {
            key: 'underlyingAsset',
            left: 'Underlying asset',
            right: 'European Union Allowance (EUA)',
          },
          {
            key: 'authority',
            left: 'Authority',
            right: 'European Commission',
          },
          {
            key: 'creationDate',
            left: 'Creation date',
            right: '01/01/2005',
          },
          {
            key: 'marketValue',
            left: 'Market value',
            right: '123,447,229,124.55 €',
          },
          {
            key: 'allowancesInCirculation',
            left: 'Allowances in circulation',
            right: '1,111,736,535',
          },
        ],
      },
    },
    portfolio: {
      aside: {
        holdings: 'Holdings',
        equivalent: 'Equivalent to',
        currentPrice: 'Current price',
        totalNetEarnings: 'Total net earnings',
        annualizedReturn: 'Total net annualized ROI',
        purchasePrice: 'Purchase price',
        totalFees: 'Total fees',
        livePrice: 'Live price',
        yearsOfAverageEmissions: '≈ {year} years of average emissions per EU citizen',
        details: 'details',
      },
      transactions: {
        title: 'Transactions history',
        noResults: 'No results.',
        header: {
          date: 'Date',
          category: 'Type',
          id: 'Id',
          from: 'From',
          to: 'To',
          amount: 'Amount',
        },
      },
      history: {
        title: 'Portfolio value',
        investedAmount: '{amount} € Invested',
      },
      details: {
        title: 'Details of fees',
        inDetails: 'In details',
        total: 'Total',
        empty: 'No results.',
      },
    },
    settings: {
      title: 'Settings',
      accountHolder: 'Account holder',
      company: 'Company',
      deleteAccount: 'Delete account',
      contactUsAt: 'Please contact us at',
      toDeleteYourAccount: 'if you want to delete your account or modify informations.',
      infoBox: {
        title: 'Verify your account!',
        description: 'Please verify your account to be able to invest in EUAs.',
        action: 'Verify account',
      },
      personalInformation: {
        title: 'Personal information',
      },
      companyInformation: {
        title: 'Company Informations',
      },
      bankAccount: {
        title: 'Bank information',
        description:
          'Add and select the bank account you want to use to deposit or withdraw your funds.',
        addBankAccount: 'Add a bank account',
        verifyAccountTooltip: 'You need to verify your account to modify this information.',
        noBankAccount: 'No bank informations.',
      },
      notifications: {
        title: 'Notifications Preferences',
        description:
          'You can’t opt out of transactional emails that we need to send you concerning your account management, your portfolio, or your contract.',
        newsletterTitle: 'Our Newsletter',
        newsletterDescription:
          'A monthly newsletter for news on carbon markets, economic news and sustainable investments that make a difference.',
        monthlyPriceTitle: 'Monthly Price Report',
        monthlyPriceDescription: 'A monthly analysis on EUAs price fluctuations.',
        productUpdateTitle: 'Product Updates',
        productUpdateDescription: 'A monthly analysis on EUAs price fluctuations.',
      },
      verification: {
        titlePersonal: 'Identity Verification',
        titleCompany: 'Registration document',
        verifiedUntil: 'Your identity is verified until',
        notVerified: 'Your identity isn’t verified. Please verify your account.',
        verify: 'Verify account',
      },
    },
  },
  dialog: {
    kyc: {
      button: 'Verify account',
      intro: {
        title: 'Verify your identity',
        description1:
          'At Homaio, your security is our top priority. To ensure a safe and compliant investment environment, we are required to verify the identity of all our investors. This verification process helps us:',
        description2:
          'Protect your investments: verifying your identity safeguards against fraud and unauthorized access.',
        description3:
          'Comply with regulations: as part of the financial industry, we adhere to regulatory requirements, including anti-money laundering (AML) and counter-terrorist financing (CTF) laws.',
        description4:
          'Maintain transparency: identity verification enhances the integrity and transparency of our platform, benefiting all our investors.',
        description5:
          'We appreciate your cooperation and understanding. Your trust is essential to us, and this step helps us protect and serve you better.',
      },
      verify: {
        title: 'Check your inbox and enter the code sent',
        calloutTitle: 'Didn’t receive an email?',
        calloutDescription:
          'If you can’t find the email in your inbox or spam folder after 5 minutes, please click below and we will send you a new one.',
        description: 'A verification code has been sent to {email}.',
        resend: 'Resend email',
      },
      legalForm: {
        title: 'Which legal form would you like to invest in?',
        description: 'Please select an option below.',
        individual: 'As an individual',
        company: 'As a company',
      },
      personalInformation: {
        title: 'Personal information',
        kycSaved: 'Your KYC has been saved',
        KycUpdated: 'Your KYC has been updated',
        placeholder: {
          firstName: 'First name',
          lastName: 'Last name',
          birthDate: 'Date of Birth',
          country: 'Country',
          address: 'Fiscal address',
          postalCode: 'Postal code',
          city: 'City',
          countryOfResidence: 'Country of residence',
          preferredLanguage: 'Preferred language',
          phoneNumber: 'Phone number',
          phoneCode: 'Dial number',
        },
      },
      companyInformation: {
        title: 'Company information',
        placeholder: {
          companyName: 'Company name',
          registrationNumber: 'Registration number',
          address: 'Address',
          city: 'City',
          postalCode: 'Postal code',
          country: 'Country',
          role: 'Role in company',
        },
      },
      registrationDocument: {
        title: 'Registration Document',
        description: 'Please upload a proof of the registration of your company (KBIS).',
        uploadTitle: 'Registration document (KBIS)',
        uploadDescription: 'drag and drop or click to upload your file',
        uploadNotice: 'We accept PDF, PNG and JPEG files.',
        removeFile: 'Remove file',
        uploadError: 'Error while uploading file',
      },
      identityDocuments: {
        title: 'Identity Document',
        description:
          'Please upload a proof of your identity. We accept Passport or National ID Card.',
        uploadTitle: 'Passport or National ID Card',
        uploadDescription: 'drag and drop or click to upload your file',
        uploadNotice: 'We accept PDF, PNG and JPEG files.',
        removeFile: 'Remove file',
        addDocument: '+ Add a back picture of your document',
        uploadError: 'Error while uploading file',
      },
      bankInformation: {
        title: 'Bank information',
        titleCompany: 'Company’s bank information',
        confirmation: 'I hereby confirm that I am the owner of this bank account.',
        confirmationCompany:
          'I hereby confirm that {companyName} is the owner of this bank account.',
        notice:
          '*We require your IBAN to verify the source of funds, as we exclusively accept deposits from bank accounts in countries not subject to financial sanctions.',
      },
      thankYou: {
        title: 'Thank you',
        message: 'Your information has been saved, we are currently verifying it...',
        messageAllDone: 'Your information has been saved and verified !',
      },
      stepper: {
        personalInformation: 'Personal information',
        companyInformation: 'Company information',
        verifying: 'We are verifying your profile',
        verified: 'Profile verified',
      },
    },
    login: {
      title: 'Welcome back!',
      noAccount: 'Don’t have an account?',
      createAccount: 'Create an account',
      forgotPassword: 'Forgot password?',
      login: 'Login',
    },
    register: {
      title: 'Invest in bespoke climate-grade assets',
      alreadyHaveAccount: 'Already have an account?',
      orContinue: 'Or continue with',
      passwordContain: 'Your password must contain:',
      login: 'Login',
      sourceLabel: 'How did you hear about Homaio?',
      offerDescription:
        'I’d like to receive updates and newsletters from Homaio to stay informed about latest news and offers.',
      createAccount: 'Create an account',
      disclaimer1: 'By confirming your email, you agree to our',
      disclaimer2: 'and that you have read and understood our',
    },
    deposit: {
      intro: {
        title: 'Make a deposit',
        calloutTitle: 'Warning',
        calloutDescription1:
          'Please note that the minimum investment is €1,000 and that you will not be able to purchase fractions of EUAs. Therefore, plan to transfer an amount greater than €1,000 or than the required amount.',
        calloutDescription2:
          'Also, if you wish to benefit from reduced fees for investments over €20,000, we recommend making a transfer of €20,500, as the amount cannot be exactly €20,000 and will be slightly higher by a few hundred euros.',
        calloutDescription3:
          'You can withdraw any remaining surplus from your Homaio euro wallet at any time by requesting a transfer.',
      },
      editBank: {
        update: 'Update IBAN',
        tooltip: 'For legal reasons, you can’t change the bank account holder.',
      },
      bankInformation: {
        bankName: 'Bank name',
        accountName: 'Account name',
        bic: 'BIC/SWIFT',
        iban: 'IBAN',
        internalReference: 'Internal reference',
      },
      informations: {
        title: 'Make a deposit',
        fromThisAccountDisclaimer:
          'For legal and security reasons, the funds needs to come from this bank account:',
        iUnderstand: 'I understand and will make the deposit with this bank account.',
        useBelow:
          'Please use the information below to make a bank transfer in EUR to your Homaio account.',
        pleaseIncludeReference:
          'Please include this reference code in the subject of the transfer to ensure that it is correctly attributed to you.',
        processingTime: 'Processing time',
        processingTimeDescription:
          'It takes 1 to 3 business days for the transfer to appear in your Homaio account and for you to be able to invest.',
      },
    },
    addBankAccount: {
      title: 'Add bank account',
      addIban: 'Add new IBAN',
      bankName: 'Bank name',
      accountNumber: 'SWIFT or IBAN',
    },
    deleteBankAccount: {
      title: 'Delete bank account?',
      subtitle: 'You need to add a new bank account to delete this one.',
    },
    changePassword: {
      title: 'Change password',
      passwordContain: 'Your password must contain:',
      oldPassword: 'Old password',
      newPassword: 'New password',
    },
  },
  common: {
    euaShort: 'EUA',
    euaLong: 'European Union Allowance',
    today: 'today',
    licenseEex: 'Data under licence from EEX',
    continueLater: 'Continue later',
    continue: 'Continue',
    back: 'Back',
    next: 'Next',
    modify: 'Modify',
    close: 'Close',
    sell: 'Sell',
    buy: 'Buy',
    invest: 'Invest',
    termsOfService: 'Terms of service',
    privacyPolicy: 'Privacy policy',
    update: 'Update',
    cancel: 'Cancel',
    delete: 'Delete',
    copied: 'Copied',
    save: 'Save',
    cancelChanges: 'Cancel changes',
    genericError: 'an error occured please try again',
    discoverSource: {
      podcast: 'Podcast',
      article: 'Article',
      linkedIn: 'LinkedIn',
      searchEngine: 'Search engine',
      homaioContributor: 'Homaio contributor',
      relatives: 'Relatives',
      event: 'Event',
      other: 'Other',
      nlSnowball: 'NL Snowball',
    },
    transactionType: {
      deposit: 'Deposit',
      withdraw: 'Withdrawal',
      buy: 'Buy',
      sell: 'Sell',
      entryFees: 'Entry fees',
      custodyFees: 'Custody fees',
    },
    timeRange: {
      short: {
        day: 'D',
        week: 'W',
        month: 'M',
        year: 'Y',
      },
      long: {
        day: 'day | day | days',
        week: 'week | week | weeks',
        month: 'month | month | months',
        year: 'year | year | years',
      },
    },
    timePeriod: {
      short: {
        '1W': '1W',
        '1M': '1M',
        '6M': '6M',
        '1Y': '1Y',
        '3Y': '3Y',
        '5Y': '5Y',
        '10Y': '10Y',
        '15Y': '15Y',
        YTD: 'YTD',
        ALL: 'All',
      },
      long: {
        '1W': '1 week',
        '1M': '1 month',
        '6M': '6 months',
        '1Y': '1 year',
        '3Y': '3 years',
        '5Y': '5 years',
        '10Y': '10 years',
        '15Y': '15 years',
        YTD: 'Year to date',
        ALL: 'All time',
      },
    },
  },
  form: {
    errors: {
      commons: {
        [COMMON_FORM_ERRORS.IS_EMAIL]: 'invalid email',
        [COMMON_FORM_ERRORS.IS_NOT_EMPTY]: 'This field is required',
      },
    },
  },
  country: {
    AFG: 'Afghanistan',
    ALA: 'Åland Islands',
    ALB: 'Albania',
    DZA: 'Algeria',
    ASM: 'American Samoa',
    AND: 'Andorra',
    AGO: 'Angola',
    AIA: 'Anguilla',
    ATA: 'Antarctica',
    ATG: 'Antigua and Barbuda',
    ARG: 'Argentina',
    ARM: 'Armenia',
    ABW: 'Aruba',
    AUS: 'Australia',
    AUT: 'Austria',
    AZE: 'Azerbaijan',
    BHS: 'Bahamas',
    BHR: 'Bahrain',
    BGD: 'Bangladesh',
    BRB: 'Barbados',
    BLR: 'Belarus',
    BEL: 'Belgium',
    BLZ: 'Belize',
    BEN: 'Benin',
    BMU: 'Bermuda',
    BTN: 'Bhutan',
    BOL: 'Bolivia',
    BES: 'Bonaire, Sint Eustatius and Saba',
    BIH: 'Bosnia and Herzegovina',
    BWA: 'Botswana',
    BVT: 'Bouvet Island',
    BRA: 'Brazil',
    IOT: 'British Indian Ocean Territory',
    BRN: 'Brunei',
    BGR: 'Bulgaria',
    BFA: 'Burkina Faso',
    BDI: 'Burundi',
    KHM: 'Cambodia',
    CMR: 'Cameroon',
    CAN: 'Canada',
    CPV: 'Cape Verde',
    CYM: 'Cayman Islands',
    CAF: 'Central African Republic',
    TCD: 'Chad',
    CHL: 'Chile',
    CHN: 'China',
    CXR: 'Christmas Island',
    CCK: 'Cocos (Keeling) Islands',
    COL: 'Colombia',
    COM: 'Comoros',
    COG: 'Congo',
    COD: 'Democratic Republic of the Congo',
    COK: 'Cook Islands',
    CRI: 'Costa Rica',
    CIV: 'Ivory Coast',
    HRV: 'Croatia',
    CUB: 'Cuba',
    CUW: 'Curaçao',
    CYP: 'Cyprus',
    CZE: 'Czech Republic',
    DNK: 'Denmark',
    DJI: 'Djibouti',
    DMA: 'Dominica',
    DOM: 'Dominican Republic',
    ECU: 'Ecuador',
    EGY: 'Egypt',
    SLV: 'El Salvador',
    GNQ: 'Equatorial Guinea',
    ERI: 'Eritrea',
    EST: 'Estonia',
    ETH: 'Ethiopia',
    FLK: 'Falkland Islands',
    FRO: 'Faroe Islands',
    FJI: 'Fiji',
    FIN: 'Finland',
    FRA: 'France',
    GUF: 'French Guiana',
    PYF: 'French Polynesia',
    ATF: 'French Southern Territories',
    GAB: 'Gabon',
    GMB: 'Gambia',
    GEO: 'Georgia',
    DEU: 'Germany',
    GHA: 'Ghana',
    GIB: 'Gibraltar',
    GRC: 'Greece',
    GRL: 'Greenland',
    GRD: 'Grenada',
    GLP: 'Guadeloupe',
    GUM: 'Guam',
    GTM: 'Guatemala',
    GGY: 'Guernsey',
    GIN: 'Guinea',
    GNB: 'Guinea-Bissau',
    GUY: 'Guyana',
    HTI: 'Haiti',
    HMD: 'Heard Island and McDonald Islands',
    VAT: 'Vatican City',
    HND: 'Honduras',
    HKG: 'Hong Kong',
    HUN: 'Hungary',
    ISL: 'Iceland',
    IND: 'India',
    IDN: 'Indonesia',
    IRN: 'Iran',
    IRQ: 'Iraq',
    IRL: 'Ireland',
    IMN: 'Isle of Man',
    ISR: 'Israel',
    ITA: 'Italy',
    JAM: 'Jamaica',
    JPN: 'Japan',
    JEY: 'Jersey',
    JOR: 'Jordan',
    KAZ: 'Kazakhstan',
    KEN: 'Kenya',
    KIR: 'Kiribati',
    PRK: 'North Korea',
    KOR: 'South Korea',
    KWT: 'Kuwait',
    KGZ: 'Kyrgyzstan',
    LAO: 'Laos',
    LVA: 'Latvia',
    LBN: 'Lebanon',
    LSO: 'Lesotho',
    LBR: 'Liberia',
    LBY: 'Libya',
    LIE: 'Liechtenstein',
    LTU: 'Lithuania',
    LUX: 'Luxembourg',
    MAC: 'Macau',
    MKD: 'North Macedonia',
    MDG: 'Madagascar',
    MWI: 'Malawi',
    MYS: 'Malaysia',
    MDV: 'Maldives',
    MLI: 'Mali',
    MLT: 'Malta',
    MHL: 'Marshall Islands',
    MTQ: 'Martinique',
    MRT: 'Mauritania',
    MUS: 'Mauritius',
    MYT: 'Mayotte',
    MEX: 'Mexico',
    FSM: 'Micronesia',
    MDA: 'Moldova',
    MCO: 'Monaco',
    MNG: 'Mongolia',
    MNE: 'Montenegro',
    MSR: 'Montserrat',
    MAR: 'Morocco',
    MOZ: 'Mozambique',
    MMR: 'Myanmar',
    NAM: 'Namibia',
    NRU: 'Nauru',
    NPL: 'Nepal',
    NLD: 'Netherlands',
    NCL: 'New Caledonia',
    NZL: 'New Zealand',
    NIC: 'Nicaragua',
    NER: 'Niger',
    NGA: 'Nigeria',
    NIU: 'Niue',
    NFK: 'Norfolk Island',
    MNP: 'Northern Mariana Islands',
    NOR: 'Norway',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PLW: 'Palau',
    PSE: 'Palestine',
    PAN: 'Panama',
    PNG: 'Papua New Guinea',
    PRY: 'Paraguay',
    PER: 'Peru',
    PHL: 'Philippines',
    PCN: 'Pitcairn Islands',
    POL: 'Poland',
    PRT: 'Portugal',
    PRI: 'Puerto Rico',
    QAT: 'Qatar',
    REU: 'Réunion',
    ROU: 'Romania',
    RUS: 'Russia',
    RWA: 'Rwanda',
    BLM: 'Saint Barthélemy',
    SHN: 'Saint Helena, Ascension and Tristan da Cunha',
    KNA: 'Saint Kitts and Nevis',
    LCA: 'Saint Lucia',
    MAF: 'Saint Martin (French part)',
    SPM: 'Saint Pierre and Miquelon',
    VCT: 'Saint Vincent and the Grenadines',
    WSM: 'Samoa',
    SMR: 'San Marino',
    STP: 'Sao Tome and Principe',
    SAU: 'Saudi Arabia',
    SEN: 'Senegal',
    SRB: 'Serbia',
    SYC: 'Seychelles',
    SLE: 'Sierra Leone',
    SGP: 'Singapore',
    SXM: 'Sint Maarten (Dutch part)',
    SVK: 'Slovakia',
    SVN: 'Slovenia',
    SLB: 'Solomon Islands',
    SOM: 'Somalia',
    ZAF: 'South Africa',
    SGS: 'South Georgia and the South Sandwich Islands',
    SSD: 'South Sudan',
    ESP: 'Spain',
    LKA: 'Sri Lanka',
    SDN: 'Sudan',
    SUR: 'Suriname',
    SJM: 'Svalbard and Jan Mayen',
    SWZ: 'Eswatini',
    SWE: 'Sweden',
    CHE: 'Switzerland',
    SYR: 'Syria',
    TWN: 'Taiwan',
    TJK: 'Tajikistan',
    TZA: 'Tanzania',
    THA: 'Thailand',
    TLS: 'Timor-Leste',
    TGO: 'Togo',
    TKL: 'Tokelau',
    TON: 'Tonga',
    TTO: 'Trinidad and Tobago',
    TUN: 'Tunisia',
    TUR: 'Turkey',
    TKM: 'Turkmenistan',
    TCA: 'Turks and Caicos Islands',
    TUV: 'Tuvalu',
    UGA: 'Uganda',
    UKR: 'Ukraine',
    ARE: 'United Arab Emirates',
    GBR: 'United Kingdom',
    USA: 'United States',
    UMI: 'United States Minor Outlying Islands',
    URY: 'Uruguay',
    UZB: 'Uzbekistan',
    VUT: 'Vanuatu',
    VEN: 'Venezuela',
    VNM: 'Vietnam',
    VGB: 'British Virgin Islands',
    VIR: 'United States Virgin Islands',
    WLF: 'Wallis and Futuna',
    ESH: 'Western Sahara',
    YEM: 'Yemen',
    ZMB: 'Zambia',
    ZWE: 'Zimbabwe',
  },
  locales: {
    aa: 'Afar',
    ab: 'Abkhazian',
    ae: 'Avestan',
    af: 'Afrikaans',
    ak: 'Akan',
    am: 'Amharic',
    an: 'Aragonese',
    ar: 'Arabic',
    as: 'Assamese',
    av: 'Avaric',
    ay: 'Aymara',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    be: 'Belarusian',
    bg: 'Bulgarian',
    bh: 'Bihari',
    bi: 'Bislama',
    bm: 'Bambara',
    bn: 'Bengali',
    bo: 'Tibetan',
    br: 'Breton',
    bs: 'Bosnian',
    ca: 'Catalan',
    ce: 'Chechen',
    ch: 'Chamorro',
    co: 'Corsican',
    cr: 'Cree',
    cs: 'Czech',
    cu: 'Church Slavic',
    cv: 'Chuvash',
    cy: 'Welsh',
    da: 'Danish',
    de: 'German',
    dv: 'Divehi',
    dz: 'Dzongkha',
    ee: 'Ewe',
    el: 'Greek',
    en: 'English',
    eo: 'Esperanto',
    es: 'Spanish',
    et: 'Estonian',
    eu: 'Basque',
    fa: 'Persian',
    ff: 'Fulah',
    fi: 'Finnish',
    fj: 'Fijian',
    fo: 'Faroese',
    fr: 'French',
    fy: 'Western Frisian',
    ga: 'Irish',
    gd: 'Scottish Gaelic',
    gl: 'Galician',
    gn: 'Guarani',
    gu: 'Gujarati',
    gv: 'Manx',
    ha: 'Hausa',
    he: 'Hebrew',
    hi: 'Hindi',
    ho: 'Hiri Motu',
    hr: 'Croatian',
    ht: 'Haitian',
    hu: 'Hungarian',
    hy: 'Armenian',
    hz: 'Herero',
    ia: 'Interlingua',
    id: 'Indonesian',
    ie: 'Interlingue',
    ig: 'Igbo',
    ii: 'Sichuan Yi',
    ik: 'Inupiaq',
    io: 'Ido',
    is: 'Icelandic',
    it: 'Italian',
    iu: 'Inuktitut',
    ja: 'Japanese',
    jv: 'Javanese',
    ka: 'Georgian',
    kg: 'Kongo',
    ki: 'Kikuyu',
    kj: 'Kwanyama',
    kk: 'Kazakh',
    kl: 'Kalaallisut',
    km: 'Khmer',
    kn: 'Kannada',
    ko: 'Korean',
    kr: 'Kanuri',
    ks: 'Kashmiri',
    ku: 'Kurdish',
    kv: 'Komi',
    kw: 'Cornish',
    ky: 'Kyrgyz',
    la: 'Latin',
    lb: 'Luxembourgish',
    lg: 'Ganda',
    li: 'Limburgish',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    lv: 'Latvian',
    mg: 'Malagasy',
    mh: 'Marshallese',
    mi: 'Māori',
    mk: 'Macedonian',
    ml: 'Malayalam',
    mn: 'Mongolian',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Maltese',
    my: 'Burmese',
    na: 'Nauru',
    nb: 'Norwegian Bokmål',
    nd: 'North Ndebele',
    ne: 'Nepali',
    ng: 'Ndonga',
    nl: 'Dutch',
    nn: 'Norwegian Nynorsk',
    no: 'Norwegian',
    nr: 'South Ndebele',
    nv: 'Navajo',
    ny: 'Chichewa',
    oc: 'Occitan',
    oj: 'Ojibwa',
    om: 'Oromo',
    or: 'Oriya',
    os: 'Ossetian',
    pa: 'Punjabi',
    pi: 'Pāli',
    pl: 'Polish',
    ps: 'Pashto',
    pt: 'Portuguese',
    qu: 'Quechua',
    rm: 'Romansh',
    rn: 'Kirundi',
    ro: 'Romanian',
    ru: 'Russian',
    rw: 'Kinyarwanda',
    sa: 'Sanskrit',
    sc: 'Sardinian',
    sd: 'Sindhi',
    se: 'Northern Sami',
    sg: 'Sango',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovenian',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Albanian',
    sr: 'Serbian',
    ss: 'Swati',
    st: 'Southern Sotho',
    su: 'Sundanese',
    sv: 'Swedish',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    tg: 'Tajik',
    th: 'Thai',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tl: 'Tagalog',
    tn: 'Tswana',
    to: 'Tongan',
    tr: 'Turkish',
    ts: 'Tsonga',
    tt: 'Tatar',
    tw: 'Twi',
    ty: 'Tahitian',
    ug: 'Uyghur',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    ve: 'Venda',
    vi: 'Vietnamese',
    vo: 'Volapük',
    wa: 'Walloon',
    wo: 'Wolof',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang',
    zh: 'Chinese',
    zu: 'Zulu',
  },
};
