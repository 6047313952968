<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { debounce } from 'lodash';
import { useForm } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import { toast } from 'vue-sonner';
import { z } from 'zod';

import KycDialogBackBtn from '@/components/kyc/KycDialogBackBtn.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TAddressInput } from '@/components/ui/address-input';
import { TButton } from '@/components/ui/button';
import { TCountryFlag } from '@/components/ui/country-flag';
import { TDialogHeader } from '@/components/ui/dialog';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import TInput from '@/components/ui/input/TInput.vue';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { alpha3ToAlpha2, Countries } from '@/constants/countries.ts';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import ApiError from '@/models/api/api-error.ts';
import { useAddressAutocompleteQuery } from '@/queries/kyc/use-address-autocomplete-query.ts';
import { useUpdateKycMutation } from '@/queries/kyc/use-update-kyc-mutation.ts';
import { CreateKycResponse } from '@/schemas/kyc/create-kyc-response-schema.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { JsonResponse } from '@/types/api/json-response-type.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const zodSchema = z.object({
  companyName: z.string(),
  companyRegistrationNumber: z.string(),
  companyAddress: z.string(),
  companyCity: z.string(),
  companyPostalCode: z.string(),
  companyRole: z.string(),
  companyCountry: z.string(),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, setFieldValue, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const { kycData } = useKycProcess(props.user);

const { closeDialog, changeModalState } = useKycDialog();

const goBack = (): void => {
  changeModalState(KycDialogState.LegalForm);
};

const preFillForm = (): void => {
  setFieldValue('companyName', kycData.value.data.companyKyc.companyName ?? '');
  setFieldValue(
    'companyRegistrationNumber',
    kycData.value.data.companyKyc.companyRegistrationNumber ?? '',
  );
  setFieldValue('companyAddress', kycData.value.data.companyKyc.companyAddress ?? '');
  setFieldValue('companyCity', kycData.value.data.companyKyc.companyCity ?? '');
  setFieldValue('companyPostalCode', kycData.value.data.companyKyc.companyPostalCode ?? '');
  setFieldValue('companyRole', kycData.value.data.companyKyc.companyRole ?? '');
  setFieldValue('companyCountry', kycData.value.data.companyKyc.companyCountry ?? '');
};

const saveKyc = (data: CreateKycResponse): void => {
  kycData.value.data.companyKyc.companyName = data.companyName;
  kycData.value.data.companyKyc.companyRegistrationNumber = data.companyRegistrationNumber;
  kycData.value.data.companyKyc.companyAddress = data.companyAddress;
  kycData.value.data.companyKyc.companyCity = data.companyCity;
  kycData.value.data.companyKyc.companyPostalCode = data.companyPostalCode;
  kycData.value.data.companyKyc.companyCountry = data.companyCountry;
  kycData.value.data.companyKyc.companyRole = data.companyRole;
};

onMounted(() => {
  if (kycData.value.data.companyKyc.companyName !== null) {
    preFillForm();
  }
});

const acceptAutocomplete = (itemIndex: number) => {
  const item = addressAutocompleteData.value?.results[itemIndex];
  setFieldValue('companyAddress', item?.address);
  setFieldValue('companyCity', item?.city);
  setFieldValue('companyPostalCode', item?.postalCode);
  setFieldValue('companyCountry', item?.country);
};

const onSubmit = handleSubmit((values) => {
  const form = {
    companyName: values.companyName,
    companyRegistrationNumber: values.companyRegistrationNumber,
    companyAddress: values.companyAddress,
    companyCity: values.companyCity,
    companyPostalCode: values.companyPostalCode,
    companyRole: values.companyRole,
    companyCountry: values.companyCountry,
  };

  updateKycMutation({ form, kycId: kycData.value.data.id });
});

const countryList = computed(() => {
  return Countries;
});

const debouncedAddress = ref('');

const updateDebouncedAddress = debounce((newValue: string) => {
  debouncedAddress.value = newValue;
}, 800);

watch(values, () => {
  updateDebouncedAddress(values.companyAddress);
});

const { data: addressAutocompleteData, isLoading: isAddressAutocompleteLoading } =
  useAddressAutocompleteQuery(debouncedAddress);

const addressSuggestion = computed(() => {
  if (addressAutocompleteData.value) {
    return addressAutocompleteData.value.results.map((item, index) => ({
      value: index,
      label: item.rawAddress,
    }));
  }
  return [];
});

const goNext = (): void => {
  changeModalState(KycDialogState.BankInformation);
};

const onUpdateKycSuccess = (response: JsonResponse) => {
  saveKyc(response as CreateKycResponse);
  goNext();
};

const onUpdateKycError = (error: ApiError) => {
  toast.error(error.data.message);
};

const { mutate: updateKycMutation, isPending: isUpdateKycPending } = useUpdateKycMutation(
  onUpdateKycSuccess,
  onUpdateKycError,
);
</script>

<template>
  <TDialogHeader
    class="absolute left-0 top-0 flex w-full flex-row justify-between px-1 py-4 pb-0 md:px-6"
  >
    <div class="w-fit">
      <LocaleSelector />
    </div>
    <div class="flex items-center justify-center gap-4">
      <p class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </p>
    </div>
  </TDialogHeader>
  <div class="no-scrollbar mt-12 flex justify-center overflow-scroll md:mt-0">
    <div class="no-scrollbar flex w-full max-w-[460px] flex-col gap-8 overflow-scroll px-2 py-4">
      <div class="mt-20">
        <KycDialogBackBtn class="w-fit" @click="goBack" />
        <h4 class="font-bold">{{ $t('dialog.kyc.companyInformation.title') }}</h4>
      </div>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="companyName">
          <FormItem>
            <FormControl>
              <TInput
                :placeholder="$t('dialog.kyc.companyInformation.placeholder.companyName')"
                v-bind="componentField"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="companyRegistrationNumber">
          <FormItem>
            <FormControl>
              <TInput
                :placeholder="$t('dialog.kyc.companyInformation.placeholder.registrationNumber')"
                v-bind="componentField"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="companyAddress">
          <FormItem>
            <FormControl>
              <TAddressInput
                :items="addressSuggestion"
                :placeholder="$t('dialog.kyc.companyInformation.placeholder.address')"
                v-bind="componentField"
                :is-loading="isAddressAutocompleteLoading"
                @update:auto-complete-value="acceptAutocomplete"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <div class="grid grid-cols-2 gap-2">
          <FormField v-slot="{ componentField }" name="companyPostalCode">
            <FormItem>
              <FormControl>
                <TInput
                  :placeholder="$t('dialog.kyc.companyInformation.placeholder.postalCode')"
                  v-bind="componentField"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="companyCity">
            <FormItem>
              <FormControl>
                <TInput
                  :placeholder="$t('dialog.kyc.companyInformation.placeholder.city')"
                  v-bind="componentField"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <FormField v-slot="{ componentField }" name="companyCountry">
          <FormItem>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <div v-if="values.companyCountry" class="flex items-center gap-2">
                    <TCountryFlag :alpha2-code="alpha3ToAlpha2[values.companyCountry]" />
                    <p>{{ $t(`country.${values.companyCountry}`) }}</p>
                  </div>
                  <SelectValue
                    v-else
                    class="text-slate-500"
                    :placeholder="$t('dialog.kyc.companyInformation.placeholder.country')"
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <RecycleScroller
                      v-slot="{ item }"
                      class="scroller"
                      :items="countryList"
                      :item-size="5"
                      key-field="code"
                    >
                      <SelectItem :value="item.code">
                        <div class="flex items-center gap-2">
                          <TCountryFlag :alpha2-code="alpha3ToAlpha2[item.code]" />
                          <p>{{ $t(`country.${item.code}`) }}</p>
                        </div>
                      </SelectItem>
                    </RecycleScroller>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="companyRole">
          <FormItem>
            <FormControl>
              <TInput
                :placeholder="$t('dialog.kyc.companyInformation.placeholder.role')"
                v-bind="componentField"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
        <TButton type="submit" :disabled="isButtonDisabled">
          <TWaveLoader v-if="isUpdateKycPending" size="sm" class="bg-white" />
          <p v-else>{{ $t('common.next') }}</p>
        </TButton>
      </form>
    </div>
  </div>
</template>
