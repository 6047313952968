import * as Sentry from '@sentry/vue';
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query';
import { createHead } from '@unhead/vue';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import VueVirtualScroller from 'vue-virtual-scroller';
import vue3GoogleLogin from 'vue3-google-login';

import en from '@/locales/en.js';
import fr from '@/locales/fr.js';
import AnalyticsPlugin from '@/plugins/analytics-plugin.ts';
import ApiPlugin from '@/plugins/api-plugin.ts';
import { router } from '@/router/router.ts';
import { UserPreferencesService } from '@/services/user-preferences-service.ts';

import App from './App.vue';

import '@/assets/fonts/fonts.css';
import '@/assets/scss/index.scss';
import { createGtm } from '@gtm-support/vue-gtm';

const gtmId = import.meta.env.VITE_GTM_ID ?? '';
const appEnv = import.meta.env.VITE_APP_ENV ?? 'dev';

const head = createHead();

const userPreferencesService = UserPreferencesService.getInstance();

const i18n = createI18n({
  locale: userPreferencesService.getLocale(),
  legacy: false,
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
  },
});

const app = createApp(App);

app.use(ApiPlugin);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true,
  queryClient,
});

app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
});

app.use(AnalyticsPlugin);

app.use(router);

if (import.meta.env.VITE_APP_ENV === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });
} else {
  console.log('Sentry is disabled in non-production environments');
}

app.use(
  createGtm({
    id: gtmId,
    enabled: appEnv === 'production',
    debug: appEnv === 'dev',
    vueRouter: router,
  }),
);

app.use(head);

app.use(i18n);

app.use(VueVirtualScroller);

app.mount('#app');
