<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { v4 as UUIDv4 } from 'uuid';
import { useForm } from 'vee-validate';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { z } from 'zod';

import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TCheckbox } from '@/components/ui/checkbox';
import {
  TCommand,
  TCommandEmpty,
  TCommandGroup,
  TCommandItem,
  TCommandList,
} from '@/components/ui/command';
import { TDialogHeader } from '@/components/ui/dialog';
import { FormControl, FormItem, FormMessage, FormField, FormLabel } from '@/components/ui/form';
import { TIcon } from '@/components/ui/icon';
import TInput from '@/components/ui/input/TInput.vue';
import { TPasswordInput } from '@/components/ui/password-input';
import { TPopover, TPopoverContent, TPopoverTrigger } from '@/components/ui/popover';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { PASSWORD_REGEX, PASSWORD_RULES } from '@/constants/password-constants.ts';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useLoginGoogleMutation } from '@/queries/auth/use-login-google-mutation.ts';
import { useRegisterMutation } from '@/queries/auth/user-register-mutation.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';

const { t: $t } = useI18n();

const zodSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .regex(PASSWORD_REGEX.minLengthRegex)
    .regex(PASSWORD_REGEX.upperCaseRegex)
    .regex(PASSWORD_REGEX.lowerCaseRegex)
    .regex(PASSWORD_REGEX.specialCharRegex)
    .regex(PASSWORD_REGEX.numberRegex),
  sources: z.array(z.string()).nonempty('You must select at least one source'),
  allowNewsletter: z.boolean().optional(),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, setFieldValue, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const register = (email: string, password: string, sources: string[], allowNewsletter: boolean) => {
  registerMutation({ email, password, sources, allowNewsletter });
};

const onSubmit = handleSubmit((values) => {
  register(values.email, values.password, values.sources, values.allowNewsletter);
});

const onRegisterSuccess = (_: User) => {
  closeDialog();
};

const onRegisterError = (error: ApiError) => {
  if (error.status === 409) {
    errorMessage.value = 'This email is already registered';
  } else {
    errorMessage.value = error.data.message;
  }
};

const { mutate: registerMutation, isPending: isRegisterPending } = useRegisterMutation(
  onRegisterSuccess,
  onRegisterError,
);

const { mutate: loginGoogleMutation } = useLoginGoogleMutation(onRegisterSuccess, onRegisterError);

const googleCallback = async (response: any) => {
  loginGoogleMutation({ credential: response.credential });
};

const { changeModalState, closeDialog } = useAuthDialog();

const setLoginState = (): void => {
  changeModalState(AuthDialogState.Login);
};

const sourceOptions = computed(() => {
  return [
    { value: 'PODCAST', label: $t('common.discoverSource.podcast') },
    { value: 'ARTICLES', label: $t('common.discoverSource.article') },
    { value: 'LINKEDIN', label: $t('common.discoverSource.linkedIn') },
    { value: 'SEARCH_ENGINE', label: $t('common.discoverSource.searchEngine') },
    { value: 'HOMAIO_CONTRIBUTOR', label: $t('common.discoverSource.homaioContributor') },
    { value: 'RELATIVES', label: $t('common.discoverSource.relatives') },
    { value: 'EVENT', label: $t('common.discoverSource.event') },
    { value: 'OTHER', label: $t('common.discoverSource.other') },
    { value: 'NL_SNOWBALL', label: $t('common.discoverSource.nlSnowball') },
  ];
});

const passwordRules = computed(() => {
  return PASSWORD_RULES.map((rule) => {
    return {
      rule: rule.rule(values),
      message: rule.message,
    };
  });
});

const selectedSources = ref<{ value: string; label: string }[]>([]);

const handleSourcesChange = (event: CustomEvent<{ value: { value: string; label: string } }>) => {
  const newSource = event.detail.value;
  if (selectedSources.value.includes(newSource)) {
    const index = selectedSources.value.findIndex((source) => source.value === newSource.value);
    selectedSources.value.splice(index, 1);
  } else {
    selectedSources.value.push(newSource);
  }

  setFieldValue(
    'sources',
    selectedSources.value.map((source) => source.value),
  );
};

setFieldValue('allowNewsletter', false);

const open = ref(false);
const value = ref('');
const passwordFocused = ref(false);
const showPasswordRules = computed(() => {
  if (passwordFocused.value) return true;
  if (!values.password) return false;

  return values.password.length > 0;
});

const registerContainer = ref<HTMLElement | null>(null);
const googleBtnKey = ref<string>(UUIDv4());

const registerContainerWidth = ref<string>('0');
const registerContainerPadding = 15;

onMounted(async () => {
  await nextTick(() => {
    googleBtnKey.value = UUIDv4();
    const containerWidth =
      Math.round(registerContainer.value?.clientWidth ?? 0) - registerContainerPadding;
    registerContainerWidth.value = containerWidth.toString();
  });
});
</script>

<template>
  <TDialogHeader>
    <div class="flex w-full items-end justify-end px-2 pt-2 lg:hidden">
      <TIcon icon="cross" class="cursor-pointer" @click="closeDialog" />
    </div>
    <div class="flex flex-row justify-between px-2 pb-0 pt-2 lg:p-6">
      <div class="w-fit">
        <LocaleSelector />
      </div>
      <div class="flex items-center justify-center gap-4">
        <p class="text-sm font-semibold text-primary-700">
          {{ $t('dialog.register.alreadyHaveAccount') }}
        </p>
        <TButton variant="outline" size="sm" @click="setLoginState">{{
          $t('dialog.register.login')
        }}</TButton>
      </div>
    </div>
  </TDialogHeader>
  <div class="no-scrollbar mt-20 flex justify-center overflow-scroll">
    <div ref="registerContainer" class="flex w-[410px] flex-col gap-4 px-2 py-4">
      <h4 class="font-bold">{{ $t('dialog.register.title') }}</h4>
      <GoogleLogin
        :key="googleBtnKey"
        class="w-full"
        :callback="googleCallback"
        :button-config="{ width: registerContainerWidth }"
      />
      <div class="flex items-center justify-between gap-2">
        <div class="h-[1px] w-[1px] flex-1 bg-primary-400" />
        <p class="text-sm font-medium text-primary-500">{{ $t('dialog.register.orContinue') }}</p>
        <div class="h-[1px] w-[1px] flex-1 bg-primary-400" />
      </div>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormControl>
              <TInput type="email" placeholder="Email" v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="password">
          <FormItem class="relative">
            <FormControl>
              <TPasswordInput
                v-bind="componentField"
                @focusin="passwordFocused = true"
                @focusout="passwordFocused = false"
              />
            </FormControl>
            <div
              v-if="showPasswordRules"
              class="absolute right-[-350px] top-[-50px] h-[240px] w-[315px] rounded-lg bg-white p-4 shadow-lg"
            >
              <p class="text-lg font-semibold text-primary-900">
                {{ $t('dialog.register.passwordContain') }}
              </p>
              <div class="mt-4 flex flex-col gap-2">
                <div
                  v-for="rule in passwordRules"
                  :key="rule.message"
                  class="flex items-center gap-2"
                  :class="{ 'text-danger-500': !rule.rule, 'text-success-500': rule.rule }"
                >
                  <TIcon size="sm" icon="checkCircleOutline" />
                  <p>{{ rule.message }}</p>
                </div>
              </div>
            </div>
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="sources">
          <FormItem>
            <FormControl>
              <div class="w-full">
                <TPopover v-model:open="open">
                  <TPopoverTrigger as-child>
                    <TButton
                      variant="select"
                      role="combobox"
                      :aria-expanded="open"
                      class="w-full justify-between"
                    >
                      <p
                        v-if="selectedSources.length > 0"
                        class="no-scrollbar flex-1 overflow-scroll text-left text-sm font-medium text-primary-700"
                      >
                        {{ selectedSources.map((source) => source.label).join(', ') }}
                      </p>
                      <p v-else class="text-sm font-medium text-primary-500">
                        {{ $t('dialog.register.sourceLabel') }}
                      </p>
                      <TIcon icon="navArrowDown" />
                    </TButton>
                  </TPopoverTrigger>
                  <TPopoverContent class="w-full p-4">
                    <TCommand v-model="value">
                      <TCommandEmpty>{{ $t('dialog.register.sourceLabel') }}</TCommandEmpty>
                      <TCommandList>
                        <TCommandGroup>
                          <TCommandItem
                            v-for="source in sourceOptions"
                            :key="source.value"
                            :value="source"
                            class="flex items-center gap-2"
                            @select="handleSourcesChange"
                          >
                            <TCheckbox
                              class="border-primary-600"
                              :checked="selectedSources.map((s) => s.value).includes(source.value)"
                            />
                            {{ source.label }}
                          </TCommandItem>
                        </TCommandGroup>
                      </TCommandList>
                    </TCommand>
                  </TPopoverContent>
                </TPopover>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <div class="flex gap-2">
          <FormField v-slot="{ value, handleChange }" type="checkbox" name="allowNewsletter">
            <FormItem class="flex flex-row items-start justify-start gap-2">
              <FormControl>
                <TCheckbox
                  class="mt-[2px] border-primary-600"
                  :checked="value"
                  @update:checked="handleChange"
                />
              </FormControl>
              <FormLabel class="!mt-0 text-sm font-medium text-primary-700">{{
                $t('dialog.register.offerDescription')
              }}</FormLabel>
              <div>
                <FormMessage />
              </div>
            </FormItem>
          </FormField>
        </div>
        <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
        <TButton type="submit" :disabled="isButtonDisabled">
          <TWaveLoader v-if="isRegisterPending" size="sm" class="bg-white" />
          <p v-else>{{ $t('dialog.register.createAccount') }}</p>
        </TButton>
      </form>
      <p class="text-xs font-medium text-primary-500">
        {{ $t('dialog.register.disclaimer1') }}
        <a
          href="https://www.homaio.com/terms-of-service"
          target="_blank"
          class="text-primary-600"
          >{{ $t('common.termsOfService') }}</a
        >
        {{ $t('dialog.register.disclaimer2') }}
        <a href="https://www.homaio.com/privacy-policy" target="_blank" class="text-primary-600">{{
          $t('common.privacyPolicy')
        }}</a
        >.
      </p>
    </div>
  </div>
</template>
