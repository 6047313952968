<script setup lang="ts">
import { CalendarDate, getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { Calendar as CalendarIcon } from 'lucide-vue-next';
import { DatePickerField, DatePickerInput, DatePickerRoot } from 'radix-vue';
import { v4 as UUIDv4 } from 'uuid';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { Calendar } from '@/components/ui/calendar';
import { TPopover, TPopoverContent, TPopoverTrigger } from '@/components/ui/popover';

const props = withDefaults(
  defineProps<{
    placeholderLabel?: string;
    calendarLabel?: string;
    initialDate?: string;
    disabled?: boolean;
  }>(),
  {
    placeholderLabel: 'Pick a date',
    calendarLabel: 'Date',
    disabled: false,
  },
);

const emits = defineEmits<{
  (e: 'input', value: string): void;
}>();

const { locale } = useI18n();

const setValue = (val: string | undefined) => {
  if (!val) return;
  if (parseDate(val) === value.value) return;

  if (!val) {
    value.value = undefined;
  } else {
    value.value = parseDate(val);
  }
  emits('input', val ?? '');
  open.value = false;
};

const placeholder = ref();
const open = ref(false);

const value = ref<CalendarDate | undefined>(
  props.initialDate ? parseDate(props.initialDate) : undefined,
);

const calendarKey = ref<string>(UUIDv4());

watch(
  () => props.initialDate,
  (newInitialDate) => {
    if (newInitialDate) {
      value.value = parseDate(newInitialDate);
    } else {
      value.value = undefined;
    }
  },
);

watch(locale, () => {
  calendarKey.value = UUIDv4();
});

const openCalendar = (): void => {
  if (props.disabled) return;
  open.value = !open.value;
};
</script>

<template>
  <TPopover v-model:open="open">
    <TPopoverTrigger as-child>
      <DatePickerRoot
        :key="calendarKey"
        :locale="locale"
        :disabled="props.disabled"
        class="bg-beige-100 disabled:text-primary-600 disabled:opacity-50"
      >
        <DatePickerField
          v-slot="{ segments }"
          v-model:model-value="value"
          :disabled="props.disabled"
          class="flex h-10 w-full select-none items-center justify-between rounded-lg border border-slate-200 p-1 text-center data-[invalid]:border-red-500"
          :class="{
            'bg-beige-100 text-primary-600 opacity-50': disabled,
            'bg-white': !disabled,
          }"
          @update:model-value="(v) => setValue(v?.toString())"
        >
          <div class="flex items-center">
            <template v-for="item in segments" :key="item.part">
              <DatePickerInput
                v-if="item.part === 'literal'"
                :part="item.part"
                class="disabled:cursor-not-allowed disabled:bg-beige-100 disabled:text-primary-600 disabled:opacity-50"
              >
                {{ item.value }}
              </DatePickerInput>
              <DatePickerInput
                v-else
                :part="item.part"
                class="rounded-md p-0.5 focus:shadow-[0_0_0_2px] focus:shadow-black focus:outline-none disabled:cursor-not-allowed disabled:bg-beige-100 disabled:text-primary-600 disabled:opacity-50 data-[placeholder]:text-slate-500"
              >
                {{ item.value.padStart(2, '0') }}
              </DatePickerInput>
            </template>
          </div>

          <CalendarIcon class="mr-2 ms-auto h-4 w-4 opacity-50" @click="openCalendar" />
        </DatePickerField>
      </DatePickerRoot>
      <input hidden />
    </TPopoverTrigger>
    <TPopoverContent class="w-auto p-0">
      <Calendar
        v-model:placeholder="placeholder"
        v-model:model-value="value"
        :calendar-label="calendarLabel"
        initial-focus
        :min-value="new CalendarDate(1900, 1, 1)"
        :max-value="today(getLocalTimeZone())"
        @update:model-value="(v) => setValue(v?.toString())"
      />
    </TPopoverContent>
  </TPopover>
</template>
