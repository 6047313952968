import { QueryFunction, QueryKey, useQuery, UseQueryReturnType } from '@tanstack/vue-query';

import { API_QUERY_KEY_ADDRESS_AUTOCOMPLETE } from '@/constants/api-query-key-constants.ts';
import { useApi } from '@/hooks/use-api.ts';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import {
  AddressAutocompleteResults,
  AddressAutocompleteResultsSchema,
} from '@/schemas/kyc/address-autocomplete-results-schema.ts';
import IApiClient from '@/types/api/api-client-interface.ts';

const createQueryFn = (api: IApiClient): QueryFunction<AddressAutocompleteResults, QueryKey> => {
  return async ({ queryKey }) => {
    const [, query] = queryKey;
    try {
      const response = await api.get<AddressAutocompleteResults>('/kyc/address/search', {
        query,
      });
      validateApiSchema(AddressAutocompleteResultsSchema, response.data);
      return response.data;
    } catch (error) {
      throw error as ApiError;
    }
  };
};

export function useAddressAutocompleteQuery(
  query: Ref<string>,
): UseQueryReturnType<AddressAutocompleteResults, Error> {
  const api = useApi();

  return useQuery<AddressAutocompleteResults, Error>({
    queryKey: [API_QUERY_KEY_ADDRESS_AUTOCOMPLETE, query],
    queryFn: createQueryFn(api),
    gcTime: 0,
  });
}
