import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { User, UserSchema } from '@/schemas/users/user-schema.ts';

type ChangePasswordVariables = { token?: string; password: string; oldPassword?: string };

export function useChangePasswordMutation(
  onSuccess?: (user: User) => void,
  onError?: (error: ApiError) => void,
) {
  const api = useApi();
  const queryClient = useQueryClient();
  const { login } = useAuth();

  return useMutation<User, ApiError, ChangePasswordVariables>({
    mutationFn: async ({ token, password, oldPassword }: ChangePasswordVariables) => {
      const url = token ? '/auth/password/token' : '/auth/password';
      const response = await api.put<User>(url, {
        token,
        password: token ? password : undefined,
        newPassword: token ? undefined : password,
        oldPassword,
      });

      validateApiSchema(UserSchema, response.data);

      return response.data;
    },
    onError: (error: Error) => {
      onError?.(error as ApiError);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });

      queryClient.setQueryData([API_QUERY_KEY_USER_ME], data);

      await login();
      onSuccess?.(data);
    },
  });
}
