import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosRequestHeaders,
  AxiosError,
} from 'axios';

import {
  API_ERR_NETWORK_NO_RESPONSE,
  API_ERR_NETWORK_SETTING_UP,
} from '@/constants/api-error-constants.ts';
import { queryString } from '@/lib/url-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { UserPreferencesService } from '@/services/user-preferences-service.ts';
import IApiClient from '@/types/api/api-client-interface.ts';
import IApiResponse from '@/types/api/api-response-interface.ts';
import { JsonResponse } from '@/types/api/json-response-type.ts';
import { JsonObject } from '@/types/json-type.ts';

class Api implements IApiClient {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        Accept: 'application/json',
      },
      withCredentials: true,
      transformResponse: [
        (data) => {
          if (typeof data === 'string') {
            try {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return JSON.parse(data);
            } catch (e) {
              return data;
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return data;
        },
      ],
    });

    this.setAppHeaders(this.axios.defaults.headers);
    this.setDynamicHeaders(this.axios.defaults.headers);

    this.axios.interceptors.request.use((config) => {
      this.setDynamicHeaders(config.headers);
      return config;
    });
  }

  private createApiResponse<T>(axiosResponse: AxiosResponse<T>): IApiResponse<T> {
    return {
      data: axiosResponse.data,
      status: axiosResponse.status,
      statusText: axiosResponse.statusText,
      headers: axiosResponse.headers as Record<string, string>,
    };
  }

  private createApiError(error: AxiosError): ApiError {
    const errorData = error.response?.data?.data
      ? error.response?.data?.data
      : error.response?.data;
    return new ApiError(error.message, error.response?.status ?? 0, {
      code: (errorData?.error as string) ?? '',
      message: errorData?.message,
      data: errorData?.errors as JsonObject,
    });
  }

  private handleError(error: AxiosError | ApiError): never {
    if (error instanceof ApiError) {
      throw error;
    }

    if (error.response) {
      throw this.createApiError(error);
    } else if (error.request) {
      throw new ApiError('No response received', 0, { code: API_ERR_NETWORK_NO_RESPONSE });
    } else {
      throw new ApiError('Error setting up the request', 0, { code: API_ERR_NETWORK_SETTING_UP });
    }
  }

  async post<T = JsonResponse>(
    url: string,
    data: any = null,
    contentType: string | null = null,
    customHeaders: Record<string, string> = {},
  ): Promise<IApiResponse<T>> {
    try {
      const config: AxiosRequestConfig = {
        headers: customHeaders,
      };

      if (contentType) {
        config.headers = { ...config.headers, 'Content-Type': contentType };
      } else {
        config.headers = { ...config.headers, 'Content-Type': 'application/json' };
      }

      const response = await this.axios.post<T>(url, data, config);
      return this.createApiResponse(response);
    } catch (error) {
      this.handleError(error as AxiosError);
    }
  }

  async put<T = JsonResponse>(
    url: string,
    data: any = null,
    contentType: string | null = null,
  ): Promise<IApiResponse<T>> {
    try {
      const config: AxiosRequestConfig = {};

      if (contentType) {
        config.headers = { 'Content-Type': contentType };
      } else {
        config.headers = { ...config.headers, 'Content-Type': 'application/json' };
      }

      const response = await this.axios.put<T>(url, data, config);
      return this.createApiResponse(response);
    } catch (error) {
      this.handleError(error as AxiosError);
    }
  }

  async delete<T = JsonResponse>(
    url: string,
    data: any = null,
    contentType: string | null = null,
  ): Promise<IApiResponse<T>> {
    try {
      const config: AxiosRequestConfig = {};

      if (contentType) {
        config.headers = { 'Content-Type': contentType };
      }

      if (data) {
        config.data = data;
      }

      const response = await this.axios.delete<T>(url, config);
      return this.createApiResponse(response);
    } catch (error) {
      this.handleError(error as AxiosError);
    }
  }

  async get<T = JsonResponse>(url: string, query: any = null): Promise<IApiResponse<T>> {
    try {
      const urlParamString = queryString(query);
      let finalUrl = url;
      finalUrl += urlParamString ? '?' + urlParamString : '';

      const response = await this.axios.get<T>(finalUrl);
      return this.createApiResponse(response);
    } catch (error) {
      this.handleError(error as AxiosError);
    }
  }

  async patch<T = JsonResponse>(
    url: string,
    data: any = null,
    contentType: string | null = null,
  ): Promise<IApiResponse<T>> {
    try {
      const config: AxiosRequestConfig = {};

      if (contentType) {
        config.headers = { 'Content-Type': contentType };
      } else {
        config.headers = { ...config.headers, 'Content-Type': 'application/json' };
      }

      const response = await this.axios.patch<T>(url, data);
      return this.createApiResponse(response);
    } catch (error) {
      this.handleError(error as AxiosError);
    }
  }

  async uploadFile(
    url: string,
    file: File,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
  ): Promise<IApiResponse<void>> {
    try {
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
        },
        withCredentials: false,
        onUploadProgress,
      };

      const response = await this.axios.put<void>(url, file, config);
      return this.createApiResponse(response);
    } catch (error) {
      this.handleError(error as AxiosError);
    }
  }

  registerResponseInterceptor(
    onFulfilled?: (response: IApiResponse<any>) => IApiResponse<any> | Promise<IApiResponse<any>>,
    onRejected?: (error: ApiError) => any,
  ): () => void {
    const interceptor = this.axios.interceptors.response.use(
      (response) => {
        const apiResponse = this.createApiResponse(response);
        return onFulfilled ? onFulfilled(apiResponse) : apiResponse;
      },
      (error: AxiosError) => {
        const apiError = this.createApiError(error);
        return onRejected ? onRejected(apiError) : Promise.reject(apiError);
      },
    );

    return () => {
      this.axios.interceptors.response.eject(interceptor);
    };
  }

  private setDynamicHeaders(headers: AxiosRequestHeaders): void {
    const userPreferencesService = UserPreferencesService.getInstance();

    // eslint-disable-next-line no-param-reassign
    headers['accept-language'] = userPreferencesService.getLocale();
  }

  private setAppHeaders(headers: AxiosRequestHeaders): void {
    // eslint-disable-next-line no-param-reassign
    headers['app-version'] = import.meta.env.VITE_APP_VERSION;
  }
}

export { Api };
