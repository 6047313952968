<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { v4 as UUIDv4 } from 'uuid';
import { useForm } from 'vee-validate';
import { computed, nextTick, onMounted, ref } from 'vue';
import { GoogleLogin } from 'vue3-google-login';
import { z } from 'zod';

import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { FormControl, FormItem, FormMessage, FormField } from '@/components/ui/form';
import { TIcon } from '@/components/ui/icon';
import TInput from '@/components/ui/input/TInput.vue';
import { TPasswordInput } from '@/components/ui/password-input';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useAuthDialog } from '@/hooks/auth/use-auth-dialog.ts';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useLoginGoogleMutation } from '@/queries/auth/use-login-google-mutation.ts';
import { useLoginMutation } from '@/queries/auth/use-login-mutation.ts';
import { User } from '@/schemas/users/user-schema.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

const zodSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, meta } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const onSubmit = handleSubmit((values) => {
  login(values.email, values.password);
});

const onLoginSuccess = (_: User) => {
  closeDialog();
};

const onLoginError = (error: ApiError) => {
  errorMessage.value = error.data.message;
};

const { mutate: loginMutation, isPending: isLoginPending } = useLoginMutation(
  onLoginSuccess,
  onLoginError,
);

const { mutate: loginGoogleMutation } = useLoginGoogleMutation(onLoginSuccess, onLoginError);

const login = (email: string, password: string) => {
  loginMutation({ email, password });
};

const googleCallback = async (response: any) => {
  loginGoogleMutation({ credential: response.credential });
};

const { changeModalState, closeDialog } = useAuthDialog();

const setRegisterState = (): void => {
  changeModalState(AuthDialogState.Register);
};

const { openDialog: openResetPasswordDialog } = useResetPasswordDialog();

const resetPassword = () => {
  closeDialog();
  openResetPasswordDialog(ResetPasswordDialogState.sendEmail);
};

const loginContainer = ref<HTMLElement | null>(null);
const googleBtnKey = ref<string>(UUIDv4());

const loginContainerWidth = ref<string>('0');
const loginContainerPadding = 15;

onMounted(async () => {
  await nextTick(() => {
    googleBtnKey.value = UUIDv4();
    const containerWidth =
      Math.round(loginContainer.value?.clientWidth ?? 0) - loginContainerPadding;
    loginContainerWidth.value = containerWidth.toString();
  });
});
</script>

<template>
  <TDialogHeader class="">
    <div class="block flex w-full items-end justify-end px-2 pt-2 lg:hidden">
      <TIcon icon="cross" class="cursor-pointer" @click="closeDialog" />
    </div>
    <div class="flex flex-row justify-between px-2 pb-0 pt-2 lg:p-6">
      <div class="w-fit">
        <LocaleSelector />
      </div>
      <div class="flex items-center justify-center gap-4">
        <p class="hidden text-sm font-semibold text-primary-700 lg:block">
          {{ $t('dialog.login.noAccount') }}
        </p>
        <TButton variant="outline" size="sm" @click="setRegisterState">{{
          $t('dialog.login.createAccount')
        }}</TButton>
      </div>
    </div>
  </TDialogHeader>
  <div class="mt-20 flex justify-center">
    <div ref="loginContainer" class="flex w-full max-w-[410px] flex-col gap-4 px-2 py-4">
      <h4 class="font-bold">{{ $t('dialog.login.title') }}</h4>
      <GoogleLogin
        :key="googleBtnKey"
        class="w-full"
        :callback="googleCallback"
        :button-config="{ width: loginContainerWidth }"
      />
      <div class="flex items-center justify-between gap-2">
        <div class="h-[1px] w-[1px] flex-1 bg-primary-400" />
        <p class="text-sm font-medium text-primary-500">Or</p>
        <div class="h-[1px] w-[1px] flex-1 bg-primary-400" />
      </div>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormControl>
              <TInput
                type="email"
                placeholder="Email"
                v-bind="componentField"
                autocomplete="username"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="password">
          <FormItem>
            <FormControl>
              <TPasswordInput v-bind="componentField" autocomplete="current-password" />
            </FormControl>
            <FormMessage />
            <p class="cursor-pointer text-xs text-primary-600" @click="resetPassword">
              {{ $t('dialog.login.forgotPassword') }}
            </p>
          </FormItem>
        </FormField>
        <p v-if="errorMessage" class="mt-2 text-sm text-danger-500">{{ errorMessage }}</p>
        <TButton type="submit" :disabled="isButtonDisabled">
          <TWaveLoader v-if="isLoginPending" size="sm" class="bg-white" />
          <p v-else>Login</p>
        </TButton>
      </form>
    </div>
  </div>
</template>
