/* eslint-disable max-len  */
/* eslint-disable sonarjs/no-duplicate-string */

import { COMMON_FORM_ERRORS } from '@/constants/form-error-constants.ts';
import { CorrelationAsset } from '@/types/charts/correlation-asset-enum.ts';

export default {
  message: {
    hello: 'bonjour le monde',
  },
  layout: {
    navBar: {
      asset: 'Actif',
      portfolio: 'Portefeuille',
      resources: 'Ressources',
      createAccount: 'Créer un compte',
      login: 'Connexion',
      userDropdown: {
        withdraw: 'Retirer',
        withdrawNoFundsTooltip: 'Vous devez avoir un solde positif pour pouvoir retirer.',
        deposit: 'Créditer',
        balance: 'Solde',
        available: 'disponible',
        items: {
          settings: 'Paramètres',
          documents: 'Documents',
          logout: 'Déconnexion',
        },
      },
    },
  },
  component: {
    bankAccountEditCard: {
      holder: 'Titulaire',
      bankName: 'Banque',
      accountNumber: 'IBAN',
      tooltipNoDeleteOnlyOne:
        'Vous devez ajouter un nouveau compte bancaire pour pouvoir supprimer celui-ci.',
      tooltipNoDeleteDefault: 'Vous ne pouvez pas supprimer votre compte bancaire sélectionné.',
    },
    asset: {
      headerTooltip:
        "Homaio vous permet d'investir sur le marché au comptant des EUA. Sur le marché au comptant, les EUA sont négociés pour une livraison immédiate, contrairement aux contrats à terme qui sont des accords d'achat ou de vente à un prix spécifié à une date ultérieure.",
      marketIndicator: {
        open: 'Marché ouvert',
        close: 'Marché fermé',
      },
      simulator: {
        lockTitle: 'Ce simulateur est verrouillé pour nos utilisateurs',
        lockButton: 'Créer un compte pour débloquer le simulateur',
      },
      srri: {
        scale: {
          safe: 'Sûr',
          careful: 'Prudent',
          balanced: 'Équilibré',
          dynamic: 'Dynamique',
        },
        risk: {
          low: 'Risque faible',
          high: 'Risque élevé',
        },
      },
      footer: {
        title: 'Mentions réglementaires',
        company: {
          name: 'HOMAIO CARBON HOLDINGS',
          form: 'Société anonyme au capital social de 37.000 €',
          address: '35 rue de Courcelles, 75008 Paris, France',
          rcs: '948 095 591 R.C.S. Paris',
          description1:
            "Les performances financières passées des quotas d'émission de l'UE (EUA) ne préjugent pas des rendements futurs. Il est conseillé aux investisseurs potentiels d'évaluer soigneusement les risques et incertitudes associés avant de s'engager dans tout investissement. Les documents mis à disposition par Homaio Carbon Holdings SA via un environnement distinct et dédié détaillent les risques potentiels, les frais, les charges et les incertitudes.",
          description2:
            "Investir dans les quotas de carbone expose à une perte partielle ou totale du capital engagé. La valeur d'un investissement peut fluctuer à la hausse comme à la baisse. Les investisseurs qui ne sont pas prêts à une perte totale de leur investissement ne devraient pas investir.",
          description3:
            "Les informations présentes sur ce site ne constituent ni une offre d'achat ou de vente de titres financiers, ni un prospectus pour l'acquisition ou la vente de titres financiers. Elles peuvent être soumises à des limites juridiques, réglementaires ou autres dans certaines juridictions. Homaio Carbon Holdings SA demande aux visiteurs de prendre connaissance et de respecter les règles qui leur sont applicables. Homaio Carbon Holdings SA n'accepte aucune responsabilité quant à la violation de ces règles par les visiteurs de ce site.",
          description4:
            "L'offre ou les offres de titres financiers émis par Homaio Carbon Holdings SA seront disponibles pour les investisseurs potentiels sur un environnement distinct et dédié, clairement indiqué comme tel. Tout investisseur souhaitant souscrire aux titres émis par Homaio Carbon Holdings est invité à prendre connaissance de manière exhaustive des termes et conditions du contrat de souscription desdits titres. Ce contrat est clairement affiché aux investisseurs concernés.",
          description5:
            "Aucune information sur ce site ou ailleurs ne doit être considérée comme une recommandation d'investissement dans un titre quelconque. Homaio Carbon Holdings SA n'offre aucun conseil en investissement ni conseil financier.",
        },
      },
    },
    passwordInput: {
      placeholder: 'Mot de passe',
    },
    pageLockedWrapper: {
      title: 'Cette page est verrouillée pour nos utilisateurs',
      button: 'Créer un compte pour débloquer le portefeuille',
    },
    AuthLockedWrapper: {
      title: 'Cette page est verrouillée pour nos utilisateurs',
      button: 'Créer un compte pour débloquer le portefeuille',
    },
    deposit: {
      from: 'De',
      bankInformation: {
        bankName: 'Nom de la banque',
        accountName: 'Numéro de compte',
        bic: 'BIC/SWIFT',
        iban: 'IBAN',
        internalReference: 'Référence interne',
      },
    },
    buy: {
      entryFeesAmount: 'dont {value} de frais',
      payWith: 'Payer avec',
      eurWallet: 'Portefeuille EUR',
      noPayment: 'Aucun solde',
      available: 'disponible',
      remaining: 'restant',
      notEmitted: 'Conservez {value} tCO2 qui ne seront pas émis par les industries',
      whyAmountChanged: 'Pourquoi le montant entré a-t-il été modifié ?',
      whyAmountChangedDescription:
        'Votre montant a été modifié car vous ne pouvez pas acheter de fractions d’EUAs. Par conséquent, nous avons arrondi à l’EUA le plus proche, ce qui a également affecté le montant entré.',
      transactionDetails: 'Détails de la transaction',
      processDetails: 'Détails du processus d’achat',
      confirm: 'Confirmer',
      continue: 'Continuer',
      acceptTerms: 'J’ai lu et j’accepte les conditions générales du',
      contract: 'contrat',
      acceptPrivacy: 'J’accepte toute communication par e-mail.',
      signContract: 'Signer le contrat',
      buyEUAs: 'Acheter des EUAs',
      investment: 'Votre investissement:',
      assets: 'Vos actifs:',
      paidWith: 'Payé avec',
      transactionFeesIncluded: 'Frais d’entrée inclus',
      seePortfolio: 'Voir le portefeuille',
      congratulations: 'Félicitations 🎉',
      equivalentTo: 'équivalent à {value} obligations HCH',
      purchasingPrice: 'Prix d’achat',
      steps: {
        contractSignature: 'Signature du contrat',
        confirmation: 'Commande confirmée par Homaio',
        orderProcessing: 'Traitement de la commande sur le marché',
        assetReception: 'Réception des EUAs',
      },
    },
    withdraw: {
      processDetails: 'Détails du processus de retrait',
      confirm: 'Confirmer',
      continue: 'Continuer',
      receive: 'Recevez',
      available: 'disponible',
      max: 'Max',
      from: 'De',
      to: 'À',
      steps: {
        requestReceived: 'Demande reçu',
        confirmation: 'Retrait effectué',
      },
    },
  },
  page: {
    resources: {
      title: 'La page des ressources arrive bientôt !',
      description:
        "Nous développons actuellement la nouvelle page des ressources et nous aimerions connaître votre avis pour nous assurer qu'elle répond à vos besoins et à vos attentes. Veuillez prendre un moment pour partager vos commentaires en remplissant notre bref questionnaire via le lien ci-dessous. Votre contribution est très appréciée.",
      button: 'Concevez cette page avec nous',
    },
    impact: {
      title: 'Impact',
      overview: {
        title: 'Aperçu',
        tooltipInstallations: 'Installations industrielles fixe couverte par le SEQE-UE',
        tooltipCountries: 'Nombre de pays impliqués dans le SEQE-UE',
        tooltipCoveredEmissions: "Part des émissions de CO2 de l'UE couverte par le SEQE-UE",
        tooltipTarget2030: 'Objectif de réduction des émissions par rapport aux émissions de 2005',
        tooltipCompliance:
          "Le taux de conformité mesure le pourcentage de quotas d'émission détenus par les entreprises qui correspond à leurs émissions réelles pour une période donnée. Il reflète la façon dont les entreprises respectent leurs exigences réglementaires dans le cadre du SEQE-UE. Un taux de conformité élevé indique que les entreprises gèrent efficacement leurs émissions et respectent leurs obligations, tandis qu'un taux faible peut suggérer des problèmes de respect ou de déclaration.",
        tooltipYearlyReductionRate:
          "Le taux de réduction annuel représente le pourcentage de diminution attendu chaque année du nombre total de quotas d'émission. Ce taux fait partie de la stratégie de l'UE pour réduire progressivement les émissions de carbone.",
        installations: { label: 'Installations', tooltip: '' },
        countries: { label: 'Pays', tooltip: '' },
        coveredEmissions: { label: 'Émissions couvertes', tooltip: '' },
        target2030: { label: 'Objectif 2030', tooltip: '' },
        compliance: { label: 'Conformité', tooltip: '' },
        yearlyReductionRate: { label: 'Taux de réduction annuel', tooltip: '' },
      },
      CO2Emissions: {
        title: 'Émissions de CO2',
        tooltip:
          "Ce graphique représente les émissions de CO2 de l'UE. Il montre comment les émissions ont diminué au fil du temps grâce au système communautaire d'échange de quotas d'émission, qui fixe des limites à la quantité totale de CO2 pouvant être émise et encourage les réductions par l'échange de quotas d'émission.",
        measureUnit: 'Gt CO2',
        change: '@:page.impact.CO2Emissions.measureUnit cette année',
      },
      emissionsVariations: {
        title: 'Variations des émissions',
        tooltip:
          "Les variations des émissions montrent la réduction des émissions de CO2 dans l'Union européenne au fil du temps.",
      },
      emissionsReductionTarget: {
        title: 'Objectif de réduction des émissions',
        tooltip:
          "Les revenus des EUAs sont utilisés pour financer des projets d'adaptation et d'atténuation du climat dans toute l'Europe. Ces fonds sont redistribués aux États membres pour les politiques climatiques nationales et soutiennent les initiatives sociales, de modernisation et d'innovation. À mesure que les prix des quotas augmentent, ces recettes augmentent également, renforçant ainsi les politiques publiques essentielles en matière de climat.",
        description:
          "L'objectif de réduction des émissions est fixé par la Commission européenne pour réduire les émissions de CO2 de -63% d'ici 2030.",
        link: 'Vous voulez en savoir plus ? Visitez notre site web',
        measureUnit: 'Gt CO2',
      },
      fundsAllocated: {
        title: 'Fonds alloués',
        tooltip:
          "Les revenus des EUAs sont utilisés pour financer des projets d'adaptation et d'atténuation du climat dans toute l'Europe. Ces fonds sont redistribués aux États membres pour les politiques climatiques nationales et soutiennent les initiatives sociales, de modernisation et d'innovation. À mesure que les prix des quotas augmentent, ces recettes augmentent également, renforçant ainsi les politiques publiques essentielles en matière de climat.",
        measureUnit: 'Md€',
        change: '@:page.impact.fundsAllocated.measureUnit cette année',
      },
      simulator: {
        title: 'Vos rendements potentiels',
        investmentAmount: "Montant de l'investissement",
        CO2Equivalent: {
          label: 'Non émis par les industries',
          measureUnit: 'tCO2',
        },
        citizensEquivalent: {
          label: 'Équivalent à',
          measureUnit: 'années',
          result: "d'émissions moyennes par citoyen de l'UE 👤",
        },
      },
    },
    performance: {
      title: 'Performance',
      separatorTitle: 'Vos rendements potentiels',
      volatilityBlock: {
        title: 'Indicateur Synthétique de Risque et de Rendement (SRRI)',
        tooltip:
          "La volatilité mesure l'ampleur des fluctuations du prix d'un actif au fil du temps. Une volatilité plus élevée indique des fluctuations de prix plus importantes, tandis qu'une volatilité plus faible indique des mouvements de prix plus stables.",
        volatility: 'Volatilité',
        description:
          'Le SRRI est utilisé pour indiquer le niveau de risque d\'un actif financier (généralement un fonds financier) en fournissant un chiffre de 1 à 7, 1 représentant un risque faible et 7 représentant un risque élevé. Les EUA ont un SRRI de 6/7 et sont dans la catégorie "risque élevé, rendement élevé".',
      },
      priceTargetBlock: {
        title: 'Projection de prix',
        link: 'Vous voulez en savoir plus ? Visitez notre site web',
        description:
          "L'objectif de prix est une estimation de la valeur future d'un actif, basée sur des rapports et des analyses d'experts. Il reflète la fourchette de prix que les experts prévoient que l'actif atteindra au cours d'une période donnée. Pour cet actif, les experts s'accordent largement sur une appréciation du prix, projetant une estimation consensuelle de 120 à 160 euros d'ici 2030.",
        research: 'Recherche',
        published: 'publiée le',
        expectedPrice: 'Prix attendu',
        predictions: {
          sebGroup: {
            description: 'Entreprise bancaire',
            date: 'Avr, 2023',
          },
          bloomberg: {
            description: 'Recherche de données financières et de marché',
            date: 'Mai, 2024',
          },
          cake: {
            description: 'Recherche scientifique en climatologie',
            date: 'Nov, 2022',
          },
          pik: {
            description: 'Recherche scientifique en climatologie',
            date: 'Nov, 2022',
          },
          morganStanley: {
            description: 'Entreprise bancaire',
            date: 'Fév, 2024',
          },
          refinitiv: {
            description: 'Recherche de données financières et de marché',
            date: 'Nov, 2022',
          },
          icis: {
            description: 'Centre de recherche de données énergétiques',
            date: 'Nov, 2022',
          },
          enerdata: {
            description: 'Centre de recherche de données énergétiques',
            date: 'Nov, 2023',
          },
        },
      },
      annualReturnBlock: {
        title: "Rendement annualisé de l'investissement",
        tooltip:
          "Cette mesure indique le rendement annuel moyen d'un investissement, exprimé en pourcentage.",
      },
      correlationBlock: {
        title: "Comparaison avec d'autres actifs",
        pastPerformances: 'Performances passées',
        priceCorrelation: 'Corrélation des prix',
        clickOnAsset: 'Cliquez sur un ou plusieurs actifs ci-dessus pour les comparer',
        tooltipCorrelation:
          "La corrélation des prix mesure comment les mouvements de prix de deux actifs sont liés. Une corrélation positive signifie que lorsque le prix d'un actif augmente ou diminue, le prix de l'autre actif tend à se déplacer dans la même direction. Une corrélation négative signifie qu'ils se déplacent dans des directions opposées.",
        tooltipPastPerformances:
          "La performance passée fait référence aux rendements historiques d'un actif sur une période spécifique. Dans notre analyse, nous utilisons un montant de base de 100 € pour comparer et calculer comment la valeur de chaque actif a évolué au fil du temps.",
        assets: {
          [CorrelationAsset.EUA]: 'EUA',
          [CorrelationAsset.BITCOIN]: 'Bitcoin',
          [CorrelationAsset.SP500]: 'S&P500',
          [CorrelationAsset.OIL]: 'Pétrole',
          [CorrelationAsset.GAS]: 'Gaz',
          [CorrelationAsset.COAL]: 'Charbon',
          [CorrelationAsset.GOLD]: 'Or',
          [CorrelationAsset.T_BILL]: 'T-Bill',
        },
      },
      euaPerformanceBlock: {
        price: 'Prix',
        tooltip:
          "Cette valeur mesure la volatilité de l'allocation de l'Union européenne (EUA) par rapport au S&P 500. Un bêta de 1 signifie que l'actif évolue en ligne avec le S&P 500. Un bêta supérieur à 1 indique une volatilité supérieure à celle du marché, tandis qu'un bêta inférieur à 1 indique une volatilité inférieure.",
        stats: {
          high: 'Haut',
          low: 'Bas',
          beta: 'Bêta',
          volatility: 'Volatilité',
          volume: 'Volume',
        },
      },
      simulationBlock: {
        investmentAmount: "Montant de l'investissement",
        investmentDuration: "Durée de l'investissement",
        forecastPortfolioValue: 'Valeur prévisionnelle du portefeuille',
        assumptionsAnnualReturn: 'Hypothèses de rendement annuel',
        subtitle:
          '*Investissement à risque. Les performances passées ne préjugent pas des performances futures.',
        notice: {
          title: 'Comment fonctionne la simulation',
          description:
            "La simulation de votre portefeuille présentée ci-dessus ne garantit aucun rendement. Les performances passées ne préjugent pas des performances futures. Votre investissement peut s'écarter du scénario, et vous pourriez perdre tout ou partie de votre investissement dans des cas extrêmes.",
          darkBlueArea:
            'La courbe bleu foncé représente la valeur estimée de votre portefeuille. Elle est calculée sur la base d\'études d\'experts externes, que vous pouvez consulter dans la section : "Projection de prix".',
          blueArea: "La zone bleue indique l'évolution la plus probable de votre investissement.",
          lightBlueArea: "La zone bleu clair indique l'évolution possible de votre investissement.",
        },
      },
      investmentSidebar: {
        invest: 'Investir',
        buy: 'Équivalent',
        bookACall: 'Prendre RDV',
        apply: 'Appliquer',
        partnerCode: 'Code partenaire',
        minimumInvestment: 'Montant minimum d’investissement de 1000 €',
        maximumInvestment: 'Pour investir 100K+,',
        enterAmount: 'Entrez un montant',
        contactUs: 'contactez-nous',
        equivalentTooltip:
          "La quantité d'obligations Homaio Carbon Holdings à laquelle vous souscrivez correspond au nombre d'actifs sous-jacents équivalents : des EUAs.",
        tooltips: {
          currentPrice:
            "Il s'agit du prix réel du marché des EUA. C'est un prix d'achat estimé car il peut fluctuer jusqu'à votre achat.",
          entryFees:
            "Les frais de transaction sont les frais du premier achat. Il s'agit d'un pourcentage de votre montant d'investissement.",
          custodyFees:
            "Les frais de garde sont un pourcentage de la valeur moyenne de votre portefeuille tout au long de l'année. Nous faisons la moyenne des valeurs quotidiennes basées sur le prix de clôture des EUA. Ces frais sont déduits à chaque date anniversaire de votre souscription. Les pourcentages dépendent de la taille de votre investissement.",
          lockupPeriod:
            'La détention minimale est une période définie pendant laquelle les investisseurs ne peuvent pas vendre leurs EUA. Cette période assure la stabilité en empêchant les ventes immédiates et aide à éviter la volatilité du marché.',
        },
        informations: {
          currentPrice: 'Prix du marché',
          entryFees: 'Frais d’entrée',
          custodyFees: 'Frais de gestion',
          lockupPeriod: 'Détention minimale',
          partnerCode: 'Code partenaire',
        },
      },
    },
    productInformation: {
      title: 'Informations sur le produit',
      investmentTermsBlock: {
        title: "Conditions d'investissement",
        subtitle:
          "Nos frais sont simples et transparents. Il n'y a pas de frais cachés, pas de frais de sortie",
        bookACall: 'Prendre RDV',
        lockupDuration: '3 mois',
        footerTitle: 'Êtes-vous un partenaire ou souhaitez-vous investir plus de 100 000 € ?',
        footerSubtitle: 'Prendre RDV avec nous pour élaborer votre offre personnalisée.',
        tables: {
          tooltips: {
            investmentFees: 'Les frais facturés lors de votre investissement initial.',
            custodyFees: "Les frais pour la conservation sécurisée de vos actifs d'investissement.",
            exitFees: 'Les frais appliqués lorsque vous retirez ou vendez votre investissement.',
            lockupPeriod:
              'Détention minimale : La période pendant laquelle vous ne pouvez pas vendre et retirer votre investissement.',
            keyInformation:
              "Un résumé des informations importantes concernant l'investissement, y compris les risques et les coûts.",
          },
          investmentFees: 'Frais d’entrée',
          custodyFees: 'Frais de gestion',
          exitFees: 'Frais de sortie',
          lockupPeriod: 'Détention minimale',
          keyInformation: "Document d'Information Synthétique",
          reduced: 'réduit',
          sixMonths: '6 mois',
        },
      },
      investmentBlock: {
        title: 'Investissement avec Homaio',
        factsheet: "Fiche d'information",
        information: [
          {
            left: 'Nom du produit',
            right: 'Obligation HCH',
          },
          {
            left: "Type d'actif",
            right: 'Obligations simples',
          },
          {
            left: 'Type de détention',
            right: 'Directe',
          },
          {
            left: 'Émetteur',
            right: 'Homaio Carbon Holdings',
          },
          {
            left: 'Compte de garde',
            right: 'Caisse des dépôts',
          },
          {
            left: 'Représentant de la masse',
            right: 'AFS',
          },
          {
            left: 'Liquidité',
            right: 'Cours du marché',
          },
          {
            left: 'Investissement minimum',
            right: '1000 €',
          },
        ],
      },
      underlyingAssetBlock: {
        title: 'Actif sous-jacent',
        information: [
          {
            key: 'underlyingAsset',
            left: 'Actif sous-jacent',
            right: 'European Union Allowance (EUA)',
          },
          {
            key: 'authority',
            left: 'Autorité',
            right: 'Commission européenne',
          },
          {
            key: 'creationDate',
            left: 'Entrée en vigueur',
            right: '01/01/2005',
          },
          {
            key: 'marketValue',
            left: 'Valeur de marché',
            right: '123 447 229 124,55 €',
          },
          {
            key: 'allowancesInCirculation',
            left: 'Quotas en circulation',
            right: '1 111 736 535',
          },
        ],
      },
    },
    portfolio: {
      aside: {
        holdings: 'Actifs',
        equivalent: 'Équivalent à',
        totalNetEarnings: 'Gains nets totaux',
        annualizedReturn: 'ROI net annualisé total',
        purchasePrice: "Prix d'achat",
        totalFees: 'Frais totaux',
        livePrice: 'Prix actuel',
        yearsOfAverageEmissions: "≈ {year} années d'émissions moyennes par citoyen de l'UE",
        details: 'détails',
      },
      transactions: {
        title: 'Historique des transactions',
        noResults: 'Aucun résultat.',
        header: {
          date: 'Date',
          category: 'Type',
          id: 'Id',
          from: 'De',
          to: 'À',
          amount: 'Montant',
        },
      },
      history: {
        title: 'Valeur du portefeuille',
        investedAmount: '{amount} € Investi',
      },
      details: {
        title: 'Détails des frais',
        inDetails: 'En détails',
        total: 'Total',
        empty: 'Pas de résultats.',
      },
    },
    settings: {
      title: 'Paramètres',
      accountHolder: 'Titulaire du compte',
      company: 'Entreprise',
      deleteAccount: 'Supprimer le compte',
      contactUsAt: 'Veuillez nous contacter à',
      toDeleteYourAccount: 'si vous souhaitez supprimer votre compte ou modifier vos informations.',
      infoBox: {
        title: 'Vérifiez votre compte !',
        description: 'Veuillez vérifier votre compte pour pouvoir investir dans les EUA.',
        action: 'Vérifier le compte',
      },
      personalInformation: {
        title: 'Informations personnelles',
      },
      companyInformation: {
        title: "Informations de l'entreprise",
      },
      bankAccount: {
        title: 'Informations bancaires',
        description:
          'Ajoutez et sélectionnez le compte bancaire que vous souhaitez utiliser pour déposer ou retirer vos fonds.',
        verifyAccountTooltip: 'Veuillez vérifier votre compte pour modifier cette information.',
        addBankAccount: 'Ajouter un compte bancaire',
        noBankAccount: 'Aucune information bancaire.',
      },
      notifications: {
        title: 'Préférences de notifications',
        description:
          'Vous ne pouvez pas vous désabonner des e-mails transactionnels que nous devons vous envoyer concernant la gestion de votre compte, votre portefeuille ou votre contrat.',
        newsletterTitle: 'Notre newsletter',
        newsletterDescription:
          "Une newsletter mensuelle pour les nouvelles sur les marchés du carbone, l'actualité économique et les investissements durables qui font la différence.",
        monthlyPriceTitle: 'Rapport mensuel des prix',
        monthlyPriceDescription: 'Une analyse mensuelle des fluctuations de prix des EUA.',
        productUpdateTitle: 'Mises à jour du produit',
        productUpdateDescription: 'Une analyse mensuelle des fluctuations de prix des EUA.',
      },
      verification: {
        titlePersonal: "Vérification d'identité",
        titleCompany: "Document d'enregistrement",
        verifiedUntil: "Votre identité est vérifiée jusqu'au",
        notVerified: "Votre identité n'est pas vérifiée. Veuillez vérifier votre compte.",
        verify: 'Vérifier le compte',
      },
    },
  },
  dialog: {
    kyc: {
      button: 'Vérifier le compte',
      intro: {
        title: 'Vérifiez votre identité',
        description1:
          "Chez Homaio, votre sécurité est notre priorité absolue. Pour garantir un environnement d'investissement sûr et conforme, nous sommes tenus de vérifier l'identité de tous nos investisseurs. Ce processus de vérification nous aide à :",
        description2:
          "Protéger vos investissements : la vérification de votre identité protège contre la fraude et l'accès non autorisé.",
        description3:
          "Se conformer aux réglementations : en tant que membre de l'industrie financière, nous adhérons aux exigences réglementaires, y compris les lois sur la lutte contre le blanchiment d'argent et le financement du terrorisme.",
        description4:
          "Maintenir la transparence : la vérification d'identité renforce l'intégrité et la transparence de notre plateforme, au bénéfice de tous nos investisseurs.",
        description5:
          'Nous apprécions votre coopération et votre compréhension. Votre confiance est essentielle pour nous, et cette étape nous aide à mieux vous protéger et vous servir.',
      },
      verify: {
        title: 'Vérifiez votre boîte de réception et saisissez le code envoyé',
        calloutTitle: "Vous n'avez pas reçu d'e-mail ?",
        calloutDescription:
          "Si vous ne trouvez pas l'e-mail dans votre boîte de réception ou dossier spam après 5 minutes, veuillez cliquer ci-dessous et nous vous en enverrons un nouveau.",
        description: 'Un code de vérification a été envoyé à {email}.',
        resend: "Renvoyer l'e-mail",
      },
      legalForm: {
        title: 'Sous quelle forme juridique souhaitez-vous investir ?',
        description: 'Veuillez sélectionner une option ci-dessous.',
        individual: "En tant qu'individu",
        company: "En tant qu'entreprise",
      },
      personalInformation: {
        title: 'Informations personnelles',
        kycSaved: 'Votre KYC a été enregistré',
        KycUpdated: 'Votre KYC a été mis à jour',
        placeholder: {
          firstName: 'Prénom',
          lastName: 'Nom',
          birthDate: 'Date de naissance',
          country: 'Pays',
          address: 'Adresse fiscale',
          postalCode: 'Code postal',
          city: 'Ville',
          countryOfResidence: 'Pays de résidence',
          preferredLanguage: 'Langue préférée',
          phoneNumber: 'Numéro de téléphone',
          phoneCode: 'Indicatif téléphonique',
        },
      },
      companyInformation: {
        title: "Informations sur l'entreprise",
        placeholder: {
          companyName: "Nom de l'entreprise",
          registrationNumber: "Numéro d'enregistrement",
          address: 'Adresse',
          city: 'Ville',
          postalCode: 'Code postal',
          country: 'Pays',
          role: "Rôle dans l'entreprise",
        },
      },
      registrationDocument: {
        title: "Document d'enregistrement",
        description: "Veuillez télécharger une preuve d'enregistrement de votre entreprise (KBIS).",
        uploadTitle: "Document d'enregistrement (KBIS)",
        uploadDescription: 'glissez-déposez ou cliquez pour télécharger votre fichier',
        uploadNotice: 'Nous acceptons les fichiers PDF, PNG et JPEG.',
        removeFile: 'Supprimer le fichier',
        uploadError: 'Erreur lors du téléchargement du fichier',
      },
      identityDocuments: {
        title: "Document d'identité",
        description:
          "Veuillez télécharger une preuve de votre identité. Nous acceptons le passeport ou la carte d'identité nationale.",
        uploadTitle: "Passeport ou carte d'identité nationale",
        uploadDescription: 'glissez-déposez ou cliquez pour télécharger votre fichier',
        uploadNotice: 'Nous acceptons les fichiers PDF, PNG et JPEG.',
        removeFile: 'Supprimer le fichier',
        addDocument: '+ Ajouter une photo du verso de votre document',
        uploadError: 'Erreur lors du téléchargement du fichier',
      },
      bankInformation: {
        title: 'Informations bancaires',
        titleCompany: "Informations bancaires de l'entreprise",
        confirmation:
          'Je confirme par la présente que je suis le propriétaire de ce compte bancaire.',
        confirmationCompany:
          'Je confirme par la présente que {companyName} est le propriétaire de ce compte bancaire.',
        notice:
          "*Nous avons besoin de votre IBAN pour vérifier la source des fonds, car nous n'acceptons que les dépôts provenant de comptes bancaires situés dans des pays non soumis à des sanctions financières.",
      },
      thankYou: {
        title: 'Merci',
        message: 'Vos informations ont été enregistrées, nous sommes en train de les vérifier...',
        messageAllDone: 'Vos informations ont été enregistrées et vérifiées !',
      },
      stepper: {
        personalInformation: 'Informations personnelles',
        companyInformation: "Informations sur l'entreprise",
        verifying: 'Nous vérifions votre profil',
        verified: 'Profil vérifié',
      },
    },
    login: {
      title: 'Bon retour !',
      noAccount: "Vous n'avez pas de compte ?",
      createAccount: 'Créer un compte',
      forgotPassword: 'Mot de passe oublié ?',
      login: 'Se connecter',
    },
    register: {
      title: 'Accédez à des actifs climatiques uniques',
      alreadyHaveAccount: 'Vous avez déjà un compte ?',
      orContinue: 'Ou continuez avec',
      passwordContain: 'Le mot de passe doit contenir:',
      login: 'Se connecter',
      sourceLabel: 'Comment avez-vous découvert Homaio ?',
      offerDescription:
        "J'aimerais recevoir des mises à jour et des newsletters de Homaio pour rester informé(e) des dernières nouvelles et offres.",
      createAccount: 'Créer un compte',
      disclaimer1: 'En confirmant votre e-mail, vous acceptez nos',
      disclaimer2: 'et vous confirmez avoir lu et compris notre',
    },
    deposit: {
      intro: {
        title: 'Effectuer un dépôt',
        calloutTitle: 'Attention',
        calloutDescription1:
          'Veuillez noter que l\'investissement minimum est de <span class="whitespace-nowrap">1 000 €</span> et que vous ne pourrez pas acheter de fractions d’EUA. Par conséquent, prévoyez de transférer un montant supérieur à 1 000 € ou au montant requis.',
        calloutDescription2:
          "De plus, si vous souhaitez bénéficier de frais réduits pour des investissements supérieurs à 20 000 €, nous vous recommandons de faire un virement de 20 500 €, car le montant ne peut pas être exactement de 20 000 € et sera légèrement supérieur de quelques centaines d'euros.",
        calloutDescription3:
          'Vous pouvez retirer à tout moment tout surplus restant de votre portefeuille en euros en demandant un virement.',
      },
      editBank: {
        update: "Mettre à jour l'IBAN",
        tooltip:
          'Pour des raisons juridiques, vous ne pouvez pas changer le titulaire du compte bancaire.',
      },
      bankInformation: {
        bankName: 'Nom de la banque',
        accountName: 'Numéro de compte',
        bic: 'BIC/SWIFT',
        iban: 'IBAN',
        internalReference: 'Référence interne',
      },
      informations: {
        title: 'Faire un dépôt',
        fromThisAccountDisclaimer:
          'Pour des raisons légales et de sécurité, les fonds doivent provenir de ce compte bancaire :',
        iUnderstand: "Je m'engage à effectuer le dépôt depuis ce compte bancaire.",
        useBelow:
          'Veuillez utiliser les informations ci-dessous pour faire un virement en EUR vers votre compte Homaio :',
        pleaseIncludeReference:
          "Merci d’inclure ce code de référence dans la libellé du virement pour garantir qu'il soit correctement attribué à votre compte.",
        processingTime: 'Délai de traitement',
        processingTimeDescription:
          "Le transfert met entre 1 et 3 jours ouvrés pour apparaître sur votre compte Homaio et vous permettre d'investir.",
      },
    },
    addBankAccount: {
      title: 'Ajouter un compte bancaire',
      addIban: "Ajouter l'IBAN",
      bankName: 'Nom de la banque',
      accountNumber: 'SWIFT ou IBAN',
    },
    deleteBankAccount: {
      title: 'Supprimer le compte bancaire ?',
      subtitle: 'Vous devez ajouter un nouveau compte pour supprimer celui-ci.',
    },
    changePassword: {
      title: 'Changer le mot de passe',
      passwordContain: 'Votre mot de passe doit contenir :',
      oldPassword: 'Ancien mot de passe',
      newPassword: 'Nouveau mot de passe',
    },
  },
  common: {
    euaShort: 'EUA',
    euaLong: 'European Union Allowance',
    today: "aujourd'hui",
    licenseEex: 'Données sous licence EEX',
    continueLater: 'Continuer plus tard',
    continue: 'Continuer',
    back: 'Retour',
    next: 'Suivant',
    modify: 'Modifier',
    close: 'Fermer',
    sell: 'Vendre',
    buy: 'Acheter',
    invest: 'Investir',
    termsOfService: "Conditions d'utilisation",
    privacyPolicy: 'Politique de confidentialité',
    update: 'Mettre à jour',
    cancel: 'Annuler',
    delete: 'Supprimer',
    copied: 'Copié',
    save: 'Sauvegarder',
    cancelChanges: 'Annuler les modifications',
    genericError: 'Une erreur est survenue, veuillez réessayer',
    discoverSource: {
      podcast: 'Podcast',
      article: 'Article',
      linkedIn: 'LinkedIn',
      searchEngine: 'Moteur de recherche',
      homaioContributor: 'Contributeur Homaio',
      relatives: 'Famille',
      event: 'Événement',
      other: 'Autre',
      nlSnowball: 'NL Snowball',
    },
    transactionType: {
      deposit: 'Dépôt',
      withdraw: 'Retrait',
      buy: 'Achat',
      sell: 'Vente',
      entryFees: 'Frais d’entrée',
      custodyFees: 'Frais de garantie',
    },
    timeRange: {
      short: {
        day: 'J',
        week: 'S',
        month: 'M',
        year: 'A',
      },
      long: {
        day: 'jour | jour | jours',
        week: 'semaine | semaine | semaines',
        month: 'mois | mois | mois',
        year: 'année | année | années',
      },
    },
    timePeriod: {
      short: {
        '1W': '1S',
        '1M': '1M',
        '6M': '6M',
        '1Y': '1A',
        '3Y': '3A',
        '5Y': '5A',
        '10Y': '10A',
        '15Y': '15A',
        YTD: 'YTD',
        ALL: 'Tout',
      },
      long: {
        '1W': '1 semaine',
        '1M': '1 mois',
        '6M': '6 mois',
        '1Y': '1 an',
        '3Y': '3 ans',
        '5Y': '5 ans',
        '10Y': '10 ans',
        '15Y': '15 ans',
        YTD: 'Année en cours',
        ALL: 'Tout le temps',
      },
    },
  },
  form: {
    errors: {
      commons: {
        [COMMON_FORM_ERRORS.IS_EMAIL]: 'email invalide',
        [COMMON_FORM_ERRORS.IS_NOT_EMPTY]: 'Ce champ est obligatoire',
      },
    },
  },
  country: {
    AFG: 'Afghanistan',
    ALA: 'Îles Åland',
    ALB: 'Albanie',
    DZA: 'Algérie',
    ASM: 'Samoa américaines',
    AND: 'Andorre',
    AGO: 'Angola',
    AIA: 'Anguilla',
    ATA: 'Antarctique',
    ATG: 'Antigua-et-Barbuda',
    ARG: 'Argentine',
    ARM: 'Arménie',
    ABW: 'Aruba',
    AUS: 'Australie',
    AUT: 'Autriche',
    AZE: 'Azerbaïdjan',
    BHS: 'Bahamas',
    BHR: 'Bahreïn',
    BGD: 'Bangladesh',
    BRB: 'Barbade',
    BLR: 'Biélorussie',
    BEL: 'Belgique',
    BLZ: 'Belize',
    BEN: 'Bénin',
    BMU: 'Bermudes',
    BTN: 'Bhoutan',
    BOL: 'Bolivie',
    BES: 'Bonaire, Saint-Eustache et Saba',
    BIH: 'Bosnie-Herzégovine',
    BWA: 'Botswana',
    BVT: 'Île Bouvet',
    BRA: 'Brésil',
    IOT: "Territoire britannique de l'océan Indien",
    BRN: 'Brunei',
    BGR: 'Bulgarie',
    BFA: 'Burkina Faso',
    BDI: 'Burundi',
    KHM: 'Cambodge',
    CMR: 'Cameroun',
    CAN: 'Canada',
    CPV: 'Cap-Vert',
    CYM: 'Îles Caïmans',
    CAF: 'République centrafricaine',
    TCD: 'Tchad',
    CHL: 'Chili',
    CHN: 'Chine',
    CXR: 'Île Christmas',
    CCK: 'Îles Cocos',
    COL: 'Colombie',
    COM: 'Comores',
    COG: 'Congo',
    COD: 'République démocratique du Congo',
    COK: 'Îles Cook',
    CRI: 'Costa Rica',
    CIV: "Côte d'Ivoire",
    HRV: 'Croatie',
    CUB: 'Cuba',
    CUW: 'Curaçao',
    CYP: 'Chypre',
    CZE: 'République tchèque',
    DNK: 'Danemark',
    DJI: 'Djibouti',
    DMA: 'Dominique',
    DOM: 'République dominicaine',
    ECU: 'Équateur',
    EGY: 'Égypte',
    SLV: 'El Salvador',
    GNQ: 'Guinée équatoriale',
    ERI: 'Érythrée',
    EST: 'Estonie',
    ETH: 'Éthiopie',
    FLK: 'Îles Malouines',
    FRO: 'Îles Féroé',
    FJI: 'Fidji',
    FIN: 'Finlande',
    FRA: 'France',
    GUF: 'Guyane française',
    PYF: 'Polynésie française',
    ATF: 'Terres australes et antarctiques françaises',
    GAB: 'Gabon',
    GMB: 'Gambie',
    GEO: 'Géorgie',
    DEU: 'Allemagne',
    GHA: 'Ghana',
    GIB: 'Gibraltar',
    GRC: 'Grèce',
    GRL: 'Groenland',
    GRD: 'Grenade',
    GLP: 'Guadeloupe',
    GUM: 'Guam',
    GTM: 'Guatemala',
    GGY: 'Guernesey',
    GIN: 'Guinée',
    GNB: 'Guinée-Bissau',
    GUY: 'Guyana',
    HTI: 'Haïti',
    HMD: 'Îles Heard-et-MacDonald',
    VAT: 'Saint-Siège (État de la Cité du Vatican)',
    HND: 'Honduras',
    HKG: 'Hong Kong',
    HUN: 'Hongrie',
    ISL: 'Islande',
    IND: 'Inde',
    IDN: 'Indonésie',
    IRN: 'Iran',
    IRQ: 'Irak',
    IRL: 'Irlande',
    IMN: 'Île de Man',
    ISR: 'Israël',
    ITA: 'Italie',
    JAM: 'Jamaïque',
    JPN: 'Japon',
    JEY: 'Jersey',
    JOR: 'Jordanie',
    KAZ: 'Kazakhstan',
    KEN: 'Kenya',
    KIR: 'Kiribati',
    PRK: 'Corée du Nord',
    KOR: 'Corée du Sud',
    KWT: 'Koweït',
    KGZ: 'Kirghizistan',
    LAO: 'Laos',
    LVA: 'Lettonie',
    LBN: 'Liban',
    LSO: 'Lesotho',
    LBR: 'Liberia',
    LBY: 'Libye',
    LIE: 'Liechtenstein',
    LTU: 'Lituanie',
    LUX: 'Luxembourg',
    MAC: 'Macao',
    MKD: 'Macédoine du Nord',
    MDG: 'Madagascar',
    MWI: 'Malawi',
    MYS: 'Malaisie',
    MDV: 'Maldives',
    MLI: 'Mali',
    MLT: 'Malte',
    MHL: 'Îles Marshall',
    MTQ: 'Martinique',
    MRT: 'Mauritanie',
    MUS: 'Maurice',
    MYT: 'Mayotte',
    MEX: 'Mexique',
    FSM: 'Micronésie',
    MDA: 'Moldavie',
    MCO: 'Monaco',
    MNG: 'Mongolie',
    MNE: 'Monténégro',
    MSR: 'Montserrat',
    MAR: 'Maroc',
    MOZ: 'Mozambique',
    MMR: 'Myanmar',
    NAM: 'Namibie',
    NRU: 'Nauru',
    NPL: 'Népal',
    NLD: 'Pays-Bas',
    NCL: 'Nouvelle-Calédonie',
    NZL: 'Nouvelle-Zélande',
    NIC: 'Nicaragua',
    NER: 'Niger',
    NGA: 'Nigeria',
    NIU: 'Niue',
    NFK: 'Île Norfolk',
    MNP: 'Îles Mariannes du Nord',
    NOR: 'Norvège',
    OMN: 'Oman',
    PAK: 'Pakistan',
    PLW: 'Palaos',
    PSE: 'Palestine',
    PAN: 'Panama',
    PNG: 'Papouasie-Nouvelle-Guinée',
    PRY: 'Paraguay',
    PER: 'Pérou',
    PHL: 'Philippines',
    PCN: 'Îles Pitcairn',
    POL: 'Pologne',
    PRT: 'Portugal',
    PRI: 'Porto Rico',
    QAT: 'Qatar',
    REU: 'La Réunion',
    ROU: 'Roumanie',
    RUS: 'Russie',
    RWA: 'Rwanda',
    BLM: 'Saint-Barthélemy',
    SHN: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    KNA: 'Saint-Kitts-et-Nevis',
    LCA: 'Sainte-Lucie',
    MAF: 'Saint-Martin (partie française)',
    SPM: 'Saint-Pierre-et-Miquelon',
    VCT: 'Saint-Vincent-et-les Grenadines',
    WSM: 'Samoa',
    SMR: 'Saint-Marin',
    STP: 'Sao Tomé-et-Principe',
    SAU: 'Arabie saoudite',
    SEN: 'Sénégal',
    SRB: 'Serbie',
    SYC: 'Seychelles',
    SLE: 'Sierra Leone',
    SGP: 'Singapour',
    SXM: 'Saint-Martin (partie néerlandaise)',
    SVK: 'Slovaquie',
    SVN: 'Slovénie',
    SLB: 'Îles Salomon',
    SOM: 'Somalie',
    ZAF: 'Afrique du Sud',
    SGS: 'Géorgie du Sud et les îles Sandwich du Sud',
    SSD: 'Soudan du Sud',
    ESP: 'Espagne',
    LKA: 'Sri Lanka',
    SDN: 'Soudan',
    SUR: 'Suriname',
    SJM: 'Svalbard et Jan Mayen',
    SWZ: 'Eswatini',
    SWE: 'Suède',
    CHE: 'Suisse',
    SYR: 'Syrie',
    TWN: 'Taïwan',
    TJK: 'Tadjikistan',
    TZA: 'Tanzanie',
    THA: 'Thaïlande',
    TLS: 'Timor-Leste',
    TGO: 'Togo',
    TKL: 'Tokelau',
    TON: 'Tonga',
    TTO: 'Trinité-et-Tobago',
    TUN: 'Tunisie',
    TUR: 'Turquie',
    TKM: 'Turkménistan',
    TCA: 'Îles Turques-et-Caïques',
    TUV: 'Tuvalu',
    UGA: 'Ouganda',
    UKR: 'Ukraine',
    ARE: 'Émirats arabes unis',
    GBR: 'Royaume-Uni',
    USA: 'États-Unis',
    UMI: 'Îles mineures éloignées des États-Unis',
    URY: 'Uruguay',
    UZB: 'Ouzbékistan',
    VUT: 'Vanuatu',
    VEN: 'Venezuela',
    VNM: 'Viêt Nam',
    VGB: 'Îles Vierges britanniques',
    VIR: 'Îles Vierges des États-Unis',
    WLF: 'Wallis-et-Futuna',
    ESH: 'Sahara occidental',
    YEM: 'Yémen',
    ZMB: 'Zambie',
    ZWE: 'Zimbabwe',
  },
  locales: {
    aa: 'Afar',
    ab: 'Abkhaze',
    ae: 'Avestique',
    af: 'Afrikaans',
    ak: 'Akan',
    am: 'Amharique',
    an: 'Aragonais',
    ar: 'Arabe',
    as: 'Assamais',
    av: 'Avar',
    ay: 'Aymara',
    az: 'Azéri',
    ba: 'Bachkir',
    be: 'Biélorusse',
    bg: 'Bulgare',
    bh: 'Bihari',
    bi: 'Bichelamar',
    bm: 'Bambara',
    bn: 'Bengali',
    bo: 'Tibétain',
    br: 'Breton',
    bs: 'Bosniaque',
    ca: 'Catalan',
    ce: 'Tchétchène',
    ch: 'Chamorro',
    co: 'Corse',
    cr: 'Cri',
    cs: 'Tchèque',
    cu: 'Vieux slave',
    cv: 'Tchouvache',
    cy: 'Gallois',
    da: 'Danois',
    de: 'Allemand',
    dv: 'Divehi',
    dz: 'Dzongkha',
    ee: 'Éwé',
    el: 'Grec',
    en: 'Anglais',
    eo: 'Espéranto',
    es: 'Espagnol',
    et: 'Estonien',
    eu: 'Basque',
    fa: 'Persan',
    ff: 'Peul',
    fi: 'Finnois',
    fj: 'Fidjien',
    fo: 'Féroïen',
    fr: 'Français',
    fy: 'Frison occidental',
    ga: 'Irlandais',
    gd: 'Gaélique écossais',
    gl: 'Galicien',
    gn: 'Guarani',
    gu: 'Gujarati',
    gv: 'Mannois',
    ha: 'Haoussa',
    he: 'Hébreu',
    hi: 'Hindi',
    ho: 'Hiri motu',
    hr: 'Croate',
    ht: 'Créole haïtien',
    hu: 'Hongrois',
    hy: 'Arménien',
    hz: 'Herero',
    ia: 'Interlingua',
    id: 'Indonésien',
    ie: 'Interlingue',
    ig: 'Igbo',
    ii: 'Yi du Sichuan',
    ik: 'Inupiak',
    io: 'Ido',
    is: 'Islandais',
    it: 'Italien',
    iu: 'Inuktitut',
    ja: 'Japonais',
    jv: 'Javanais',
    ka: 'Géorgien',
    kg: 'Kongo',
    ki: 'Kikuyu',
    kj: 'Kuanyama',
    kk: 'Kazakh',
    kl: 'Groenlandais',
    km: 'Khmer',
    kn: 'Kannada',
    ko: 'Coréen',
    kr: 'Kanouri',
    ks: 'Kashmiri',
    ku: 'Kurde',
    kv: 'Komi',
    kw: 'Cornique',
    ky: 'Kirghiz',
    la: 'Latin',
    lb: 'Luxembourgeois',
    lg: 'Ganda',
    li: 'Limbourgeois',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lituanien',
    lu: 'Luba-Katanga',
    lv: 'Letton',
    mg: 'Malgache',
    mh: 'Marshallais',
    mi: 'Maori',
    mk: 'Macédonien',
    ml: 'Malayalam',
    mn: 'Mongol',
    mr: 'Marathi',
    ms: 'Malais',
    mt: 'Maltais',
    my: 'Birman',
    na: 'Nauruan',
    nb: 'Norvégien bokmål',
    nd: 'Ndébélé du Nord',
    ne: 'Népalais',
    ng: 'Ndonga',
    nl: 'Néerlandais',
    nn: 'Norvégien nynorsk',
    no: 'Norvégien',
    nr: 'Ndébélé du Sud',
    nv: 'Navajo',
    ny: 'Nyanja',
    oc: 'Occitan',
    oj: 'Ojibwé',
    om: 'Oromo',
    or: 'Oriya',
    os: 'Ossète',
    pa: 'Pendjabi',
    pi: 'Pali',
    pl: 'Polonais',
    ps: 'Pachto',
    pt: 'Portugais',
    qu: 'Quechua',
    rm: 'Romanche',
    rn: 'Kirundi',
    ro: 'Roumain',
    ru: 'Russe',
    rw: 'Kinyarwanda',
    sa: 'Sanskrit',
    sc: 'Sarde',
    sd: 'Sindhi',
    se: 'Same du Nord',
    sg: 'Sangho',
    si: 'Cinghalais',
    sk: 'Slovaque',
    sl: 'Slovène',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Albanais',
    sr: 'Serbe',
    ss: 'Swati',
    st: 'Sotho du Sud',
    su: 'Soundanais',
    sv: 'Suédois',
    sw: 'Swahili',
    ta: 'Tamoul',
    te: 'Télougou',
    tg: 'Tadjik',
    th: 'Thaï',
    ti: 'Tigrigna',
    tk: 'Turkmène',
    tl: 'Tagalog',
    tn: 'Tswana',
    to: 'Tongien',
    tr: 'Turc',
    ts: 'Tsonga',
    tt: 'Tatar',
    tw: 'Twi',
    ty: 'Tahitien',
    ug: 'Ouïghour',
    uk: 'Ukrainien',
    ur: 'Ourdou',
    uz: 'Ouzbek',
    ve: 'Venda',
    vi: 'Vietnamien',
    vo: 'Volapük',
    wa: 'Wallon',
    wo: 'Wolof',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang',
    zh: 'Chinois',
    zu: 'Zoulou',
  },
};
