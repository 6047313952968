<template>
  <img v-bind="$attrs" :src="props.src" :alt="props.alt" :title="props.title" />
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: 'Image',
  },
  alt: {
    type: String,
    default: 'Image',
  },
});
</script>
