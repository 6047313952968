export enum CorrelationAsset {
  EUA = 'EUA',
  SP500 = 'SP500',
  OIL = 'OIL',
  GAS = 'GAS',
  COAL = 'COAL',
  GOLD = 'GOLD',
  T_BILL = 'T_BILL',
  BITCOIN = 'BITCOIN',
}
