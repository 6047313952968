import { type VariantProps, cva } from 'class-variance-authority';

export { default as TButton } from './TButton.vue';

export const buttonVariants = cva(
  'font-manrope inline-flex items-center justify-center whitespace-nowrap rounded-md font-bold ring-offset-white' +
    ' transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2' +
    ' disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary-800 text-white hover:bg-primary-900',
        primaryDark: 'bg-primary-900 text-white hover:opacity-90',
        light: 'bg-primary-200 text-primary-700 hover:bg-primary-300',
        gray: 'bg-[#EAE7E4] text-primary-700 hover:bg-primary-300',
        'light-outline':
          'border font-semibold text-primary-800 border-primary-400 bg-transparent bg-white hover:bg-slate-100 hover:text-primary-900 hover:border-primary-800',
        outline:
          'border text-primary-800 border-primary-800 bg-transparent hover:bg-primary-100 hover:text-primary-900 hover:border-primary-800',
        select: 'border bg-white text-primary-800 border-primary-400',
        secondary: 'bg-secondary text-white hover:bg-secondary/80',
        ghost: '',
        destructive: 'bg-danger-500 text-white hover:opacity-90',
        link: 'text-primary-600 hover:text-primary-900 font-semibold hover:font-bold transition-all duration-300',
        info: 'bg-info-600 text-white hover:opacity-90',
        infoLight: 'bg-info-200 text-info-600 hover:opacity-90',
        warning: 'bg-warning-600 text-white hover:opacity-90',
        warningLight: 'bg-warning-200 text-warning-600 hover:opacity-90',
        danger: 'bg-danger-600 text-white hover:opacity-90',
        dangerLight: 'bg-danger-200 text-danger-600 hover:opacity-90',
        success: 'bg-success-600 text-white hover:opacity-90',
        successLight: 'bg-success-200 text-success-600 hover:opacity-90',
      },
      size: {
        default: 'h-10 min-h-10 rounded-lg text-ui-m px-4 py-2',
        xs: 'h-7 min-h-7 rounded-lg text-ui-m px-2',
        sm: 'h-9 min-h-9 rounded-lg text-ui-m px-3',
        lg: 'h-11 min-h-11 rounded-lg text-ui-m px-8',
        'fluid-default': 'h-10 min-h-10 w-full rounded-lg text-ui-m',
        icon: 'h-10 w-10 min-h-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
