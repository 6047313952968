import { z } from 'zod';

const LegalFormSchema = z.enum(['INDIVIDUAL', 'COMPANY']);

export const UserKycSchema = z.object({
  id: z.string(),
  userId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  legalForm: LegalFormSchema,
  birthDate: z.string(),
  address: z.string(),
  address2: z.string().nullable(),
  city: z.string(),
  postalCode: z.string(),
  country: z.string(),
  countryOfResidence: z.string(),
  preferredLanguage: z.string(),
  phoneNumber: z.string(),
  phoneCode: z.string(),
  companyName: z.string().nullable(),
  companyRegistrationNumber: z.string().nullable(),
  companyAddress: z.string().nullable(),
  companyCity: z.string().nullable(),
  companyPostalCode: z.string().nullable(),
  companyRole: z.string().nullable(),
  companyCountry: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  documents: z.array(z.any()),
  user: z.object({
    email: z.string().email(),
  }),
});

export type UserKyc = z.infer<typeof UserKycSchema>;
