import { z } from 'zod';

import { API_ERR_ZOD_VALIDATION } from '@/constants/api-error-constants.ts';
import { captureMessage } from '@/lib/sentry-helpers.ts';
import ApiError from '@/models/api/api-error.ts';

function describeSchemaBriefly(schema: z.ZodType<any>): string {
  if (schema instanceof z.ZodObject) {
    const shape = schema._def.shape();
    const props = Object.entries(shape)
      .map(([key, value]) => `${key}: ${describeSchemaBriefly(value)}`)
      .join(', ');
    return `{ ${props} }`;
  }
  if (schema instanceof z.ZodArray) {
    return `${describeSchemaBriefly(schema.element)}[]`;
  }
  if (schema instanceof z.ZodEnum) {
    return `enum(${schema._def.values.map((v) => `'${v}'`).join(' | ')})`;
  }
  if (schema instanceof z.ZodUnion) {
    return schema._def.options.map(describeSchemaBriefly).join(' | ');
  }
  return schema._def.typeName;
}

export function validateApiSchema<T>(schema: z.ZodType<T>, data: unknown): T {
  try {
    return schema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      const missingProperties = error.issues.map((issue) => issue.path.join('.'));
      const schemaDescription = describeSchemaBriefly(schema);

      const message =
        'Schema validation failed.\n' +
        `Expected schema: ${schemaDescription}\n` +
        `Missing or invalid properties: ${missingProperties.join(', ')}\n` +
        `Validation errors: ${JSON.stringify(error.errors, null, 2)}`;

      const zodError = new ApiError(message, 0, {
        code: API_ERR_ZOD_VALIDATION,
        message: 'an error occured',
      });

      captureMessage(message);

      throw zodError;
    }
    throw error;
  }
}
