import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { BankAccount, BankAccountSchema } from '@/schemas/users/bank-account-schema.ts';

type BankAccountVariable = { userId: string; bankName: string; accountNumber: string };

export function useCreateUserBankAccountMutation(
  onSuccess?: (response: BankAccount) => void,
  onError?: (error: ApiError) => void,
) {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<BankAccount, ApiError, BankAccountVariable>({
    mutationFn: async (data: BankAccountVariable) => {
      const response = await api.post<BankAccount>(`/users/${data.userId}/bank-accounts`, {
        bankName: data.bankName,
        accountNumber: data.accountNumber,
      });

      validateApiSchema(BankAccountSchema, response.data);

      return response.data;
    },
    onError: (error: Error) => {
      onError?.(error as ApiError);
    },
    onSuccess: async (response: BankAccount) => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });

      onSuccess?.(response);
    },
  });
}
