<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { z } from 'zod';

import { TButton } from '@/components/ui/button';
import { TDialogHeader } from '@/components/ui/dialog';
import { FormControl, FormItem, FormField } from '@/components/ui/form';
import { TIcon } from '@/components/ui/icon';
import { TPasswordInput } from '@/components/ui/password-input';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { PASSWORD_REGEX, PASSWORD_RULES } from '@/constants/password-constants.ts';
import { useResetPasswordDialog } from '@/hooks/auth/use-reset-password-dialog.ts';
import ApiError from '@/models/api/api-error.ts';
import { useChangePasswordMutation } from '@/queries/auth/use-change-password-mutation.ts';
import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

defineProps<{
  currentEmail: string;
}>();

defineEmits<{
  (e: 'update:email', payload: string | undefined): void;
}>();

const zodSchema = z.object({
  password: z
    .string()
    .regex(PASSWORD_REGEX.minLengthRegex)
    .regex(PASSWORD_REGEX.upperCaseRegex)
    .regex(PASSWORD_REGEX.lowerCaseRegex)
    .regex(PASSWORD_REGEX.specialCharRegex)
    .regex(PASSWORD_REGEX.numberRegex),
});

const passwordRules = computed(() => {
  return PASSWORD_RULES.map((rule) => {
    return {
      rule: rule.rule(values),
      message: rule.message,
    };
  });
});

const formSchema = toTypedSchema(zodSchema);

const { handleSubmit, isSubmitting, meta, values } = useForm({
  validationSchema: formSchema,
});

const isFormValid = computed(() => meta.value.valid);
const isButtonDisabled = computed(() => !isFormValid.value || isSubmitting.value);

const errorMessage = ref('');

const router = useRouter();
const route = useRoute();

const onSubmit = handleSubmit((formValues) => {
  resetPassword(formValues.password);
});

const onResetPasswordSuccess = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const onResetPasswordError = (error: ApiError) => {
  errorMessage.value = error.data.message;
};

const { mutate: resetPasswordMutation, isPending: isresetPasswordPending } =
  useChangePasswordMutation(onResetPasswordSuccess, onResetPasswordError);

const resetPassword = (password: string) => {
  resetPasswordMutation({ password, token: route.query['reset-password-token'] as string });
};

const { closeDialog, changeModalState } = useResetPasswordDialog();

const closeDialogAndRemoveQuery = async () => {
  await router.replace({ query: {} });
  closeDialog();
};

const goBack = async () => {
  await router.replace({ query: {} });
  changeModalState(ResetPasswordDialogState.sendEmail);
};

const passwordFocused = ref(false);
const showPasswordRules = computed(() => {
  if (passwordFocused.value) return true;
  if (!values.password) return false;

  return values.password.length > 0;
});
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between px-2 py-4 pb-0 md:px-6 md:py-6">
    <div class="w-fit">
      <TIcon icon="arrowLeft" @click="goBack" />
    </div>
    <div class="w-fit">
      <TIcon icon="cross" @click="closeDialogAndRemoveQuery" />
    </div>
  </TDialogHeader>
  <div class="mt-20 flex justify-center">
    <div class="flex w-full max-w-[410px] flex-col gap-4 px-2 py-4">
      <h4 class="mb-4 font-bold">Enter a new password</h4>
      <form class="flex flex-col gap-4" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="password">
          <FormItem class="relative">
            <FormControl>
              <TPasswordInput
                v-bind="componentField"
                @focusin="passwordFocused = true"
                @focusout="passwordFocused = false"
              />
            </FormControl>
            <div
              v-if="showPasswordRules"
              class="absolute right-[-350px] top-[-50px] h-[240px] w-[315px] rounded-lg bg-white p-4 shadow-lg"
            >
              <p class="text-lg font-semibold text-primary-900">Your password must contain:</p>
              <div class="mt-4 flex flex-col gap-2">
                <div
                  v-for="rule in passwordRules"
                  :key="rule.message"
                  class="flex items-center gap-2"
                  :class="{ 'text-danger-500': !rule.rule, 'text-success-500': rule.rule }"
                >
                  <TIcon size="sm" icon="checkCircleOutline" />
                  <p>{{ rule.message }}</p>
                </div>
              </div>
            </div>
          </FormItem>
        </FormField>
        <p v-if="errorMessage" class="text-sm text-danger-500">{{ errorMessage }}</p>
        <TButton type="submit" :disabled="isButtonDisabled">
          <TWaveLoader v-if="isresetPasswordPending" size="sm" class="bg-white" />
          <p v-else>Reset password</p>
        </TButton>
      </form>
      <p class="mt-2 text-sm font-medium text-primary-600">
        Having trouble logging in?
        <a href="mailto:team@homaio.com" class="underline underline-offset-4">Contact support</a>
      </p>
    </div>
  </div>
</template>
